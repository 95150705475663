import React from 'react'
import './prodacts.scss'
import img from 'constants/img'
import CartFusion from 'components/Cart/CartFusion'
import { FetchApi } from 'context/FetchApi'
import { useContext, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Products = () => {
  const location = useLocation();
  let {t} =useTranslation()
  let { products, fetchProducts } = useContext(FetchApi);
  const query = new URLSearchParams(location.search);
  const search = query.get('search');

  useEffect(() => {
    fetchProducts(35, 1, '', '', false, false, false, '', true, search);
    window.scroll(0, 0)
  }, [search]);
  return (
    <div>
      <div className="app_products">
        <div className="nav_products">
          <div className="left">
            <span> {t('Search_for')} :  {search}</span>
          </div>
          <div className="right">
            <span>{products?.length}  {t('Results')}</span>
          </div>
        </div>
        <div className="body_products">
          <div className="grid   d-flex justify-content-start  align-items-center" >
            {
              products?.map((item, index) => (
                <div key={index} className="lg:col-3 xl:col-3 md:col-6 sm:col-12 mt-4">
                  <CartFusion m={'m-2'}
                    id={item?.IDBrandProduct}
                    img={item.BrandProductLogo}
                    price={item?.BrandProductPrice}
                    title={item?.BrandProductTitle}
                    offer={item?.BrandProductOfferPrice}
                    path={`/details/${item?.IDBrandProduct}`}

                  />
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Products
