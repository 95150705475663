import { FetchApi } from 'context/FetchApi';
import { addToCart } from 'helper/cartApis';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { useContext, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './cart.scss';
import { ProgressSpinner } from 'primereact/progressspinner';

const CartFusion = ({ id, img, path, title, dec, price, offer, m }) => {
    let { t } = useTranslation()
    const [loading, setLoading] = useState(false)
    let { getCart } = useContext(FetchApi);

    const header = (
        <Link to={path}>
            <div className="flex justify-content-center align-items-center">
                {
                    img ?
                        <img alt="Card" className='  pt-2 img_cart' src={img} /> :
                        <div className="pt-6 pb-6">
                            <ProgressSpinner />
                        </div>
                }
            </div>
        </Link>
    );
    const addCart = async () => {
        setLoading(true)
        addToCart(id, 1).then(async (data) => {
            await getCart()
            setTimeout(() => {
                setLoading(false);
            }, 1000);
            toast.success(t('save_mess'))
        })
    }
    const footer = (item) => {
        return (
            <div className='add_cart flex justify-content-center align-item-center'>
                <Button onClick={addCart} loading={loading} label={t('add_cart')} /* icon="pi pi-shopping-cart" */ className='p-button-danger ' />
            </div>
        );
    }
    function formatDiscount(offer, price) {
        let discount = ((price - offer) / price) * 100;
        if (discount % 1 !== 0) {
            return discount.toFixed(1) + '%';
        } else {
            return discount.toFixed(0) + '%';
        }
    }
    return (
        <div className={`app_products_card   ${m}`}>
            <Card title={title.slice(0, 50)+"..."} subTitle={dec} footer={footer} header={header} className="md:w-full">
                <Link to={path}>
                    <div className="price flex flex-row gap-3 justify-content-  align-items-center">
                        {
                            offer > 0 ?
                                <div className="offer_price flex justify-content-center align-items-center">
                                    <span>{price} LE</span>
                                </div> : ''
                        }
                        {
                            offer > 0 ?
                                <div className="price_local bg">
                                    <span>{offer} LE</span>
                                </div> : <div className="price_local bg">
                                    <span>{price} LE</span>
                                </div>
                        }
                    </div>
                    {
                        offer > 0 &&
                        <div className="percentage">
                            <span>{formatDiscount(offer, price)}</span>
                        </div>
                    }
                </Link>
            </Card>
        </div>
    )
}

export default CartFusion
