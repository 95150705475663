import CartFusion from 'components/Cart/CartFusion';
import img from 'constants/img';
import { useTranslation } from 'react-i18next';

const Featured = ({ data }) => {
    let { t } = useTranslation()
    return (
        <>
            <div className="app_Featured mt-8 mb-8">
                <span className='header' data-aos="flip-left"
                    data-aos-duration="2000">{t('featured')}  </span>
                <p className='body' data-aos="flip-left"
                    data-aos-duration="2000">  {t('featured_des')}  </p>
                <div className="card_prime mt-5">
                    <div className="grid ">
                        {
                            data?.map((item, index) => (
                                <div data-aos="fade-up"
                                    data-aos-duration={500 * (index + 1)} key={index} className="lg:col-3 xl:col-3 md:col-6 sm:col-6 xs:col-4 card_col_sm  mt-4">
                                    <CartFusion
                                        id={item?.IDBrandProduct} m={'m-0'}
                                        path={`/details/${item?.IDBrandProduct}`}
                                        title={item?.BrandProductTitle}
                                        img={item.BrandProductLogo}
                                        price={item?.BrandProductPrice}
                                        offer={item.BrandProductOfferPrice} />
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default Featured