
 
const routes = [
    {
        path: "/login",
        nameEn: "Login",
        nameAr: "تسجيل الدخول",
        icon: <i className='pi pi-sign-in'></i>,
    },
    {
        path: "/profile",
        nameEn: "My Profile",
        nameAr: "ملفي الشخصي",
        icon: <i className='pi pi-user'></i>,
    }, 

    {
        path: "/orders",
        nameEn: "  My Orders",
        nameAr: "طلباتي",
        icon: <i className='pi pi-shopping-cart'></i>,
    },
   
 
];

export default routes