import axios from 'axios';
import Cookies from 'js-cookie';
import { createContext, useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast'
import { FetchApi } from './FetchApi';
export const LocalizationContext = createContext([])

function LangChange({ children }) {

  const [isLang, setIsLang] = useState(localStorage.getItem('i18nextLng'));
  const [isOpen, setIsOpen] = useState(true);
  const [Profile, setProfile] = useState(true);
  let { setCartsNum, brands } = useContext(FetchApi); 

  
  const toggle = () => setIsOpen(!isOpen);
  useEffect(() => {
    localStorage.setItem('i18nextLng', isLang)
  }, [isLang])

  async function LogOut() {
    const url = `${process.env.REACT_APP_API_URL}/logout`;
    axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        // 'Content-Type': 'multipart/form-data', 
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      },
    }).then(({ data }) => { 
      setCartsNum(0)
      localStorage.removeItem('IDCart')
      toast.success(isLang === "en" ? "This account has been signed out" : "تم تسجيل خروج هذا الحساب")
      localStorage.removeItem("token");
      localStorage.removeItem("IDClient");
    })

  }

  async function getdata() {
    if (localStorage.getItem('IDClient')) {
      const url = `${process.env.REACT_APP_API_URL}/profile`;
      let { data } = await axios.get(url, {
        headers: {
          'Content-Type': 'multipart/form-data',
          // 'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        }
      })
      setProfile(data?.Response);   
      
    }
  }

  useEffect(() => {
    getdata()
  }, [])

  return (
    <LocalizationContext.Provider value={{
      isLang,
      setIsLang,
      isOpen,
      setIsOpen,
      toggle,
      Profile,
      LogOut,
      getdata
    }}>
      {children}
    </LocalizationContext.Provider>
  )
}

export default LangChange