import CartFusion from 'components/Cart/CartFusion';
import img from 'constants/img';
import { FetchApi } from 'context/FetchApi';
import { LocalizationContext } from 'context/LangChange';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

export const CategoryProducts = () => {
  const location = useLocation();
  const navigate = useNavigate();
  let { t } = useTranslation()

  const [active, setActive] = useState(null)

  const query = new URLSearchParams(location.search);
  const categoryId = query.get('categoryId');
  const subCategoryId = query.get('subCategoryId');

  let { products, fetchProducts, Category, fetchCategory, subCategory, fetchSubCategory } = useContext(FetchApi);
  let { isLang } = useContext(LocalizationContext);

  const handelCategory = useCallback(async (IDCategory, IDSubCategory) => {
    await fetchSubCategory(IDCategory);
    await setActive(IDSubCategory);
    await navigate(`/category/search?categoryId=${IDCategory}&subCategoryId=${IDSubCategory}`);
    if (IDSubCategory) {
      await fetchProducts(9, 1, IDCategory, IDSubCategory, false, true);
    } else {
      await fetchProducts(9, 1, IDCategory, IDSubCategory, true, false);
    }
  }, []);


  const tabsSubCategory = useCallback(async (IDSubCategory) => {
    await setActive(IDSubCategory);
    await navigate(`/category/search?categoryId=${categoryId}&subCategoryId=${IDSubCategory}`);;
    if (IDSubCategory) {
      await fetchProducts(9, 1, categoryId, IDSubCategory, false, true, false, '', false, '');
    };
  }, [categoryId]);


  const getData = async () => {
    await fetchSubCategory(categoryId)
    await setActive(subCategoryId);

    if (subCategoryId > 0) {
      await fetchProducts(9, 1, categoryId, subCategoryId, false, true, false, '', false, '');
    } else {
      await fetchProducts(9, 1, categoryId, subCategoryId, true, false, false, '', false, '');
    }
  };
  useEffect(() => {
    fetchCategory()
    getData()

    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [categoryId, subCategoryId, localStorage.getItem('i18nextLng')]);



  return (
    <>
    
      <div className="app_category_page">
        <div className="nav_category_page category_side_lg">
          <div className="left">
            <span>   {Category?.filter?.((item) => categoryId == item?.IDCategory)[0]?.CategoryName} {t('Category')}   </span>
          </div>
          <div className="right">
            <span>{t('items')} : {products?.length}</span>
          </div>
        </div>
        <div className="grid" >
          {/* category in sidebar */}
          <div className="lg:col-3 xl:col-3 md:col-4 sm:col-12 category_side_lg">
            <div className="body_category_page">
              <div className='header'>
                <span>{t('CATEGORIES').charAt(0).toUpperCase() + t('CATEGORIES').slice(1)?.toLowerCase()}</span> 
              </div>
              <ul>
                {Category?.map((item, index) => (<li key={index} onClick={() => handelCategory(item?.IDCategory, item?.IDSubCategory)} className={`${item?.IDCategory == categoryId ? 'bg-red-900' : ''}`}> <img src={item.CategoryIcon} alt="" /> <span>{item?.CategoryName}</span> </li>))}
              </ul>
            </div>
          </div>
          {/* category in navbar */}
          <div className="category_side_sm ">
            {Category?.map((item, index) => (
              <div key={index} onClick={() => handelCategory(item?.IDCategory, item?.IDSubCategory)} className={`${item?.IDCategory == categoryId ? 'bg-red-900' : ''} col-3 category-item  m-0 p-0 `}>
                <img src={item.CategoryIcon} alt="icon category" /> <span>{item?.CategoryName}</span>
              </div>
            ))}
          </div>

          <div className="lg:col-9 xl:col-9 md:col-8  sm:col-12 h-full  flex justify-content-center flex-column  category_in_navbar">
            {/* subCategory navbar  */}
            <div className=" mt-5 tabs_Category flex justify-content-center  w-full">
              <div className='flex gap-3  '>
                {subCategory?.map((item, index) => (<Button key={index} size='small' raised className={`${item?.IDSubCategory == active ? 'active' : ''}`} label={item?.SubCategoryName} onClick={() => tabsSubCategory(item?.IDSubCategory)} />))}
              </div>
            </div>

            {/* Card product in website */}
            <div className="mt-3 cart_category_lg">
              <div className="grid cart_Category cart_category_lg  ">
                {
                  products?.map((item, index) => (
                    <div key={index} className="col-12 md:col-4">
                      <CartFusion m={'m-2'}
                        id={item?.IDBrandProduct}
                        img={item.BrandProductLogo}
                        price={item?.BrandProductPrice}
                        title={item?.BrandProductTitle}
                        offer={item?.BrandProductOfferPrice}
                        path={`/details/${item?.IDBrandProduct}`}
                      />
                    </div>
                  ))
                }
              </div>
            </div>

          </div>
        </div>
      </div>

      {/* Card product in mobile */}
      <div className="container card_sm_device mb-8">
        <div className="grid">
          {
            products?.map((item, index) => (
              <div key={index} className="col-6">
                <CartFusion
                  id={item?.IDBrandProduct}
                  img={item.BrandProductLogo}
                  price={item?.BrandProductPrice}
                  title={item?.BrandProductTitle}
                  offer={item?.BrandProductOfferPrice}
                  path={`/details/${item?.IDBrandProduct}`}
                />
              </div>
            ))
          }

        </div>
      </div>

    </>
  )
}
