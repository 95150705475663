import React, { useContext, useEffect, useState } from 'react'
import './style.scss'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios';
import { RadioButton } from 'primereact/radiobutton';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { LocalizationContext } from 'context/LangChange';
import toast from 'react-hot-toast';
import { FetchApi } from 'context/FetchApi';
import img from 'constants/img';
import { Modal } from 'react-bootstrap';
const Payment = () => {
    let { t } = useTranslation()
    let navigate=useNavigate()
    const url = `${process.env.REACT_APP_API_URL}/payment/methods`;
    const urlOrder = `${process.env.REACT_APP_API_URL}/orders/add`;
    const [modalDone, setModalDone] = useState(false);

    const [Data, setData] = useState(null);
    const [loading, setLoading] = useState(false);

    let { setCartsNum } = useContext(FetchApi);
    let { Profile } = useContext(LocalizationContext)


    async function getdata() {
        let { data } = await axios.get(url, {
            headers: {
                'Content-Type': 'multipart/form-data',
                // 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            }
        })
        setData(data?.Response);
    }

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        getdata()
    }, [])

    const ChangeMainAddres = async (id) => {

    }
    const makeOrder = async (id) => {
        setLoading(true);
        try {
            let { data } = await axios.post(urlOrder, {
                IDPaymentMethod: 1,
                IDCart: Number(localStorage.getItem('IDCart')),

            }, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    // 'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                }
            })
            console.log(data);
            if (data?.Success === true) {
                setTimeout(function () { 
                    setModalDone(false)
                    navigate(`/`)
                }, 1500);
                toast.success(t('save_mess'))
                setModalDone(true)
            }
            setLoading(false);
            setCartsNum(0)
        } catch (error) {
            setLoading(false);
        }
    }
    return (
        <>
            <div className="app_payment">
                <div className="nav_products">
                    <div className="left flex gap-4 justify-content-center align-items-center ">
                        <Link to='/cart' >
                            <i className='pi pi-arrow-left text-white'></i>
                        </Link>
                        <span>{t('payment3')} </span>
                    </div>
                    <div className="right">
                        {/*  <span>150 Results</span> */}
                    </div>
                </div>
                <div className="payment_container mt-7">
                    <div className="grid">
                        {
                            Data?.map((item, index) => (
                                <div className="app_my_address" key={index}>
                                        <div className="grid  mt-3  content" >
                                            <div className="col-9 ">
                                                <div className="flex align-items-center ">
                                                    <RadioButton inputId="ingredient1" name="pizza" value="1" onChange={(e) => ChangeMainAddres(item)} checked={item?.IDPaymentMethod === 1} />
                                                    <label htmlFor="ingredient1" className="address_title ml-2">{item?.PaymentMethodName}</label>
                                                </div>
                                                {
                                                    (Profile?.ClientAddresses?.filter((item) => item?.MainAddress === 1))?.map((item, index) => (
                                                        <div className="grid px-5 mt-2 content_contect" >
                                                            <div className="lg:col-6 xl:col-6 md:col-6 sm:col-12">
                                                                <div className='mt-2 content_contect-text'> <strong >{t('name')}</strong>  <span>{item?.AddressName}</span>  </div>
                                                            </div>
                                                            <div className="lg:col-6 xl:col-6 md:col-6 sm:col-12">
                                                                <div className='mt-2 content_contect-text'> <strong> {t('contact')}  </strong>{item?.AddressPhone}</div>
                                                            </div>
                                                            <div className="lg:col-12 xl:col-12 md:col-12 sm:col-12">
                                                                <div className='mt-2 content_contect-text'> <strong>{t('address')}</strong>  {item?.ClientAddress}</div>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                            <div className=" col-3  flex justify-content-end align-items-baseline ">
                                                <div className='add_cart flex  mb-6 align-item-center'>
                                                    <Link to={'/address'}>
                                                        <Button label={t('edit')} className='p-button-danger ' />
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                            ))
                        }
                    </div>
                    <div className='add_cart  submit_payment  flex  mt-6 align-item-center justify-content-center'>
                        <Button loading={loading} label={t('payment')} onClick={makeOrder} className='p-button-danger ' />
                    </div>
                </div>
            </div>
            <Modal
                show={modalDone}
                onHide={() => setModalDone(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body style={{ height: '100vh' }} className='flex justify-content-center align-item-center '>
                    <div style={{ width: '400px' }} className='h-100  flex justify-content-center align-item-center flex-column '>
                        <img src={img.Image_Reservations} alt="image logo" loading='lazy' />
                         <h1 className='text-center text_done_Reservations'>{t('mess_order')}</h1> 
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Payment
