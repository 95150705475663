import img from 'constants/img'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Dropdown } from 'primereact/dropdown'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import '../Prodacts/prodacts.scss'
import { useEffect } from 'react'
import toast from 'react-hot-toast'
import { deleteCarts, editCart, getCart } from 'helper/cartApis'
import { FetchApi } from 'context/FetchApi'
import { Link } from 'react-router-dom'
import LogoSvg from 'constants/logo'
import { Dialog } from 'primereact/dialog'

import cartEmpty from 'assets/cart.json'
import { Player } from '@lottiefiles/react-lottie-player'
import { Sidebar } from 'primereact/sidebar'

const Orders = () => {
  let { t } = useTranslation()
  let { setCartsNum } = useContext(FetchApi);

  const [dataCart, setData] = useState(null)
  const [dataAllData, setDataAllData] = useState(false);
  const [loadingStates, setLoadingStates] = useState({});
  const [loadingRemove, setLoadingRemove] = useState({});
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const onItemChange = (item, e) => {
    setLoadingStates(prev => ({ ...prev, [item.IDCartItem]: true }));
    editCart(item.IDCartItem, e.value).then((data) => {
      setTimeout(() => {
        setLoadingStates(prev => ({ ...prev, [item.IDCartItem]: false }));
      }, 1000);
      fetchCart();
      toast.success(t('save_mess'));
    }).catch(() => {
      setLoadingStates(prev => ({ ...prev, [item.IDCartItem]: false }));
      toast.error(t('error_mess'));
    });
  };

  const items = Array.from({ length: 200 }).map((_, i) => ({ label: `${i + 1}`, value: i + 1 }));
  const data = [
    { column1: t('current_price'), column2: dataAllData?.CartPrice },
    { column1: t('shipping'), column2: dataAllData?.ShippingAmount },
    { column1: t('discount'), column2: dataAllData?.CartOfferPrice },
    { column1: t('Total'), column2: dataAllData?.CartTotalPrice },
  ];
  const filteredData = data.filter(item => !(item.column2 === 0 && item.column1 === t('discount')));

  const fetchCart = () => {
    getCart().then((data) => {
      setData(data?.Response?.CartItems);
      setDataAllData(data?.Response)
      setCartsNum(data?.Response?.CartItems?.length > 0 ? data?.Response?.CartItems?.length : 0)
    })
  }

  useEffect(() => {
    fetchCart()
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [localStorage.getItem('i18nextLng')])

  const deleteCart = (item) => {
    setLoadingRemove(prev => ({ ...prev, [item.IDCartItem]: true }));
    deleteCarts(item.IDCartItem).then((data) => {
      setTimeout(() => {
        setLoadingRemove(prev => ({ ...prev, [item.IDCartItem]: false }));
      }, 1000);
      fetchCart();
      toast.success(t('save_mess'));
    }).catch(() => {
      setLoadingRemove(prev => ({ ...prev, [item.IDCartItem]: false }));
      toast.error(t('error_mess'));
    });
  }

  const deleteAllCart = (item) => {
    setLoading(true)
    deleteCarts(item.IDCartItem).then((data) => {
      fetchCart();
      toast.success(t('save_mess'));
      setLoading(false)
      setVisible(false)
    }).catch(() => {
      toast.error(t('error_mess'));
      setLoading(false)
    });
  }
  const [visibleBottom, setVisibleBottom] = useState(false);



  return (
    <>
      <div className="app_products">
        <div className="nav_products flex align-items-center">
          <div className="left">
            <span>{t('Shopping_Cart')}</span>
          </div>
          <LogoSvg.Delete onClick={(e) => setVisible(true)} className="cursor-pointer" />
        </div>
        {
          dataCart?.length > 0 ?
            <div className="grid   d-flex justify-content-start  align-items- " >
              <div className="lg:col-9 xl:col-9 md:col-12 sm:col-12   ">
                <div className="body_order">
                  <div className="grid d-flex justify-content-start  align-items- " >
                    {
                      dataCart?.map((item, index) => (
                        <div key={index} className="lg:col-12 xl:col-12 md:col-12 sm:col-12 container_cart_sm ">
                          <div className="item">
                            <div className="grid d-flex justify-content-start  align-items- " >
                              <div className="col-3  cart_image_sm_device">
                                <Link to={`/details/${item?.IDBrandProduct}`}>
                                  <img src={item?.BrandProductLogo} className='w-100  object-fit-contain' alt="" />
                                </Link>
                              </div>
                              <div className="col-6 ">
                                <h1>  {item?.BrandProductTitle} </h1>
                                <div className="price flex flex-row gap-3">
                                  {item?.BrandProductOfferPrice > 0 ?
                                    <div className="offer_price flex justify-content-center align-items-center">
                                      <span>{item?.BrandProductPrice} LE</span>
                                    </div> : ''}
                                  {item?.BrandProductOfferPrice > 0 ?
                                    <div className="price_local bg">
                                      <span>{item?.BrandProductOfferPrice} LE</span>
                                    </div> : <div className="price_local bg">
                                      <span>{item?.BrandProductPrice} LE</span>
                                    </div>}
                                </div>
                                {/*<div className="Stock">
                              <span>In Stock</span>
                            </div> */}
                                <div className="quntity mt-5">
                                  <h5></h5>
                                  <Dropdown
                                    loading={loadingStates[item.IDCartItem]}
                                    value={item?.CartItemQuantity}
                                    options={items}
                                    onChange={(e) => {
                                      onItemChange(item, e)
                                    }}
                                    virtualScrollerOptions={{ itemSize: 38 }}
                                  />
                                </div>
                              </div>
                              <div className="col-3 flex justify-content-end align-items-start">
                                <div className='add_cart flex justify-content-center align-item-center'>
                                  <Button loading={loadingRemove[item.IDCartItem]} onClick={() => { deleteCart(item) }} label={t('Remove')} size='small' className='p-button-danger ' />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    }

                  </div>
                </div>
              </div>
              <div className="body_order_summry pay_lg_device  lg:col-3 xl:col-3 md:col-6 sm:col-12    ">
                <DataTable size='large' showGridlines value={filteredData} className="p-datatable-striped">
                  <Column field="column1" header="" body={(rowData) => <strong>{rowData.column1}</strong>} />
                  <Column field="column2" header="" />
                </DataTable>
                <div className='add_cart mt-4 flex justify-content-center align-item-center'>
                  <Link to='/payment'>
                    <Button label={t('Checkout')} size='small' className='p-button-danger ' />
                  </Link>
                </div>

              </div>
              <div className="pay_sm_device">
                <Button label={t('btn_pay')} onClick={() => setVisibleBottom(true)} size='small' className='p-button-danger ' />
                <Sidebar className='add_cart_sm' visible={visibleBottom} position="bottom" onHide={() => setVisibleBottom(false)}>
                  <div className="grid">
                    <div className="col-12">
                      <div className="summary">
                        <div className="summary-item">
                          <span className="text">{t('current_price')}</span>
                          <span className="price">{dataAllData?.CartPrice}</span>
                        </div>
                        <div className="summary-item">
                          <span className="text">{t('shipping')}</span>
                          <span className="price">{dataAllData?.ShippingAmount}</span>

                        </div>
                        {
                          dataAllData?.CartOfferPrice > 0 &&
                          <div className="summary-item">
                            <span className="text">{t('discount')}</span>
                            <span className="price">{dataAllData?.CartOfferPrice}</span>
                          </div>

                        }
                        <div className="summary-item">
                          <span className="text">{t('Total')}</span>
                          <span className="price">{dataAllData?.CartTotalPrice}</span>

                        </div>
                      </div>
                    </div>

                    <div className="col-12  add_cart_sm">
                      <Link to='/payment'>
                        <Button label={t('Checkout')} size='small' className='p-button-danger ' />
                      </Link>
                    </div>
                  </div>
                </Sidebar>

              </div>

            </div> :
            <div className="Player">
              <Player
                className='w-4 mt-4'
                src={cartEmpty}
                autoplay
                loop
              />
            </div>
        }
      </div>
      <Dialog header={t('Delete_order')} visible={visible} onHide={() => setVisible(false)}
        style={{ width: '30vw' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }}>
        <div className="confirmation-content   flex justify-content-start  gap-3 align-items-center">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
          <span>{t('delete_mess')}
          </span>
        </div>
        <div className='btn_save_cansel mt-4 flex gap-3 justify-content-center align-item-center'>
          <Button label={t('cancel')} className='' type='button' onClick={() => setVisible(false)} outlined severity='' size='small' />
          <Button onClick={deleteAllCart} loading={loading} label={t('save')} type='submit' className='p-button-danger  ' size='small' />
        </div>
      </Dialog>
    </>
  )
}

export default Orders
