import img from 'constants/img';
import LogoSvg from 'constants/logo';
import { LocalizationContext } from 'context/LangChange';
import { motion } from 'framer-motion';
import i18next from 'i18next';
import cookies from 'js-cookie';
import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Sidebar } from 'primereact/sidebar';
import { useContext, useEffect, useRef, useState } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './navbar.scss';
import routes from './routes';
import axios from 'axios';
import { FetchApi } from 'context/FetchApi';
import { OverlayPanel } from 'primereact/overlaypanel';

const languages = [

    {
        code: 'en',
        name: 'English',
        dir: 'ltr',
        country_code: 'gb',
    },
    {
        code: 'ar',
        name: 'العربية',
        dir: 'rtl',
        country_code: 'sa',
    },
]
const NavBar = ({ navbarColor }) => {
    const menuRef = useRef(null);
    const op = useRef(null);

    const [activeLink, setActiveLink] = useState(0);
    const location = useLocation();
    const currentLanguageCode = cookies.get('i18next') || 'en'
    const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
    const { t } = useTranslation()

    let { isLang, setIsLang, LogOut } = useContext(LocalizationContext);
    let { cartNum, brands } = useContext(FetchApi);
    useEffect(() => {
        document.body.dir = currentLanguage.dir || 'ltr';
    }, [currentLanguage, t])
    const handleLanguageChange = async (selectedLanguageCode) => {
        setIsLang(selectedLanguageCode);
        const url = `${process.env.REACT_APP_API_URL}/language/change`;
        let token = localStorage.getItem('token')
        if (token) {
            if (selectedLanguageCode === 'en') {
                await axios.post(url, { ClientLanguage: selectedLanguageCode }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    }
                }).then(() => {
                    let token = localStorage.getItem('token')
                    if (token) {
                        window.location.reload();
                    }
                    document.body.dir = 'ltr';
                })
            } else {
                await axios.post(url, { ClientLanguage: selectedLanguageCode }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    }
                }).then(() => {
                    let token = localStorage.getItem('token')
                    if (token) {
                        window.location.reload();
                    }
                    document.body.dir = 'rtl';
                })

            }

        } else {
            if (selectedLanguageCode === 'en') {
                document.body.dir = 'ltr';
            } else {
                document.body.dir = 'rtl';
            }
        }
        i18next.changeLanguage(selectedLanguageCode);
    };
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            setScrollPosition(window.scrollY);
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    const handleLinkClick = (index) => {
        setActiveLink(index);
        setVisible(false)
    };
    const [visible, setVisible] = useState(false);
    const [categoryType, setCategoryType] = useState(sessionStorage.getItem('CATEGORY_TYPE') || 'COSMETICS');
    useEffect(() => {
        const handleStorageChange = () => {
            setCategoryType(sessionStorage.getItem('CATEGORY_TYPE') || 'COSMETICS');
        };
        window.addEventListener('storage', handleStorageChange);
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);
    const handelSaveType = () => {
        const newType = categoryType !== "COSMETICS" ? "COSMETICS" : "CLOTHES";
        sessionStorage.setItem('CATEGORY_TYPE', newType);
        setCategoryType(newType);
        window.location.reload();

    };
    const filteredRoutes = routes.filter(route => {
        if (localStorage.getItem("token")) {
            return !route.path.includes("/login");
        } else {
            return (!route.path.includes("/orders") && !route.path.includes("/profile"))
        }
    });
    let navigator = useNavigate()
    const [searchTerm, setSearchTerm] = useState('');

    const handleInputChange = (e) => {
        setSearchTerm(e.target.value);
    };
    const handleEnterPress = (e) => {
        if (e.key === 'Enter') {
            navigator(`/products?search=${encodeURIComponent(searchTerm)}`);
        }
    };

    const [showSearch, setShowSearch] = useState(false);

    const toggleSearch = () => {
        setShowSearch(!showSearch);
    };
    return (
        <div className='app__navbar '  >
            <Container>
                <Navbar
                    dir='ltr'
                    fixed={scrollPosition > 600 ? 'top' : 'top'}
                    collapseOnSelect
                    expand="sm"
                    className={`${navbarColor} ${scrollPosition > 600 ? 'scroll navbar-navOnthorPAge shadow ' : ''} shadow-sm`}
                >
                    <div className="flex justify-content-between    align-items-center  w-100 container-fluid"
                        dir={cookies.get('i18next') === 'en' ? 'rtl' : 'ltr'}
                    >
                        <div className="nav_logo_sm">
                            <div className="nav_links flex justify-content-between nav_bar_am ">
                                <div className="sidebar_sm" >
                                    <Nav className='flex justify-content-center align-items-center gap-6'>
                                        <Nav.Item className='flex justify-content-center align-items-center gap-5  '>
                                            <div className="menu flex justify-content-center align-items-center" style={{ fontSize: '2rem', position: 'relative', zIndex: '1' }}>
                                                <LogoSvg.Menu onClick={() => setVisible(true)} />
                                            </div>
                                            <Link to={'/cart'}>
                                                <i className="card_icons p-overlay-badge" style={{ fontSize: '2rem', position: 'relative', zIndex: '2' }}>
                                                    <Badge severity="danger" value={cartNum ? cartNum : 0}></Badge>
                                                    <LogoSvg.Card />
                                                </i>
                                            </Link>
                                        </Nav.Item>
                                        <Nav className={`${cookies.get('i18next') === "en" ? 'dropdown_en' : 'dropdown_ar'}`}>
                                            <Nav.Link as={Link} to='/about' className={`${location.pathname === '/about' ? 'active_nav' : 'text-light'}`}>{t('nav_about')} </Nav.Link>
                                            <Nav.Link onClick={(e) => op.current.toggle(e)} className={`  ${location.pathname.includes('/brands') ? 'active_nav' : 'text-light'}`}>
                                                {t('nav_brands')}
                                                <OverlayPanel ref={op} dir='ltr' dismissable={true} onHide={() => op.current.hide()}>
                                                    <div className="brand-grid">
                                                        {brands?.map((item, index) => (
                                                            <div key={index} className="brand-icon mt-4">
                                                                <Link to={`/brands/${item?.IDBrand}`}>
                                                                    <img src={item.BrandLogo} alt={`Brand ${index}`} />
                                                                </Link>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </OverlayPanel>
                                            </Nav.Link>
                                            <Nav.Link as={Link} to='/category' className={`${location.pathname === '/category' || location.pathname === '/category/search' ? 'active_nav' : 'text-light'}`}>{t('nav_categories')} </Nav.Link>
                                            <Nav.Link as={Link} to='/' className={` ${location.pathname === '/' ? 'active_nav' : 'text-light'}`}>{t('nav_home')}</Nav.Link>
                                        </Nav>
                                    </Nav>
                                    <Nav className={`${isLang === "en" ? "inputgroup_searchen" : "inputgroup_searchAe"} flex justify-content-center align-items-center`}>
                                        <div className="p-inputgroup w-20rem flex-1" dir={isLang === "en" ? 'ltr' : 'rtl'}>
                                            <InputText
                                                value={searchTerm}
                                                onChange={handleInputChange}
                                                onKeyDown={handleEnterPress}
                                                placeholder={t('Search')}
                                                className='p-inputtext-sm w-full'
                                            />
                                            <Link to={`/products?search=${encodeURIComponent(searchTerm)}`}>
                                                <Button icon="pi pi-search" className="p-button-danger" size='small' />
                                            </Link>
                                        </div>
                                    </Nav>
                                    <div className="nav_logo_lg   flex  justify-content-center gap-7 align-content-center  align-items-center">
                                        <div className="btn_type">
                                            <Button
                                                size='small'
                                                raised
                                                onClick={handelSaveType}
                                                icon={categoryType !== "COSMETICS" ? <LogoSvg.COSMETICS /> : <LogoSvg.Apparel />}
                                                label={categoryType !== "COSMETICS" ? t('cosmetics') : t('apparel')}
                                                iconPos="left"
                                                className="p-button-danger"
                                            />

                                        </div>
                                        <img src={img.logo} width={100} alt="logo" />
                                    </div>


                                </div>
                            </div>
                        </div>
                        <div className="nav_logo_sidebar     flex justify-content-between  align-items-center">
                            <div className=' flex w-100 flex-row  align-items-center gap-3'>
                                {
                                    !showSearch &&
                                    <div className="sidebar_lg">
                                        <img src={img.Drawer} alt="Drawer" onClick={() => setVisible(true)} />
                                    </div>
                                }

                                <div
                                    dir='ltr'
                                    className={`search ${showSearch ? 'show-search' : ''}`}
                                    id="search-bar w-100"
                                >
                                    <input
                                        type="search"
                                        placeholder={t('Search')}
                                        name="q"
                                        onChange={handleInputChange}
                                        onKeyDown={handleEnterPress}
                                        className="search__input w-100"
                                    />

                                    <div className="search__button" id="search-button" onClick={toggleSearch}>
                                        <i className="pi pi-search search__icon"></i>
                                        <i className="pi pi-times search__close"></i>
                                    </div>

                                </div>
                            </div>
                            <Button
                                size='small'
                                raised
                                onClick={handelSaveType}
                                icon={categoryType !== "COSMETICS" ? <LogoSvg.COSMETICS /> : <LogoSvg.Apparel />}
                                iconPos="left"
                                className="p-button-danger"
                                rounded
                                text
                            />

                            {
                                !showSearch &&
                                <img src={img.logo} alt='logo nagro' />
                            }
                        </div>

                    </div>
                </Navbar>
            </Container>

            {/* Side bar in home page full size */}
            <Sidebar className="bg_sidebar side_nav px-4   w-full md:w-20rem lg:w-30rem md:w-30rem" position="right" visible={visible} onHide={() => setVisible(false)}  >
                <div className="flex justify-content-center align-items-center" >
                    <img src={img.bg_logo} width={200} alt="" className='m-auto' />
                </div>
                <div className="navbar_sm_page   ">
                    <div className="   d-flex flex-column   " >
                        <section className={'routes'}>
                            {filteredRoutes?.map((root, i) => {
                                return (
                                    <div key={i} className='mt-3'>
                                        <Link
                                            to={root.path}
                                            className={`link ${activeLink === i ? 'active' : 'active'} ${isLang === "en" ? 'justify-content-start' : 'justify-content-start'}`}
                                            onClick={() => handleLinkClick(i)}
                                        >
                                            <div className={`icon`} id={root.name}>
                                                {root.icon}
                                            </div>
                                            <div className="link_text">{isLang === "en" ? root.nameEn : root.nameAr}</div>
                                        </Link>
                                    </div>
                                );
                            })}
                        </section>
                        {/* logout btn */}
                        {
                            localStorage.getItem("token") &&
                            <section className={'routes'}>
                                <div className='mt-3'>
                                    <div
                                        className={`link ${activeLink === 2 ? 'active' : 'active'} ${isLang === "en" ? 'justify-content-start' : 'justify-content-start'}`}
                                        onClick={() => {
                                            LogOut()
                                            handleLinkClick()
                                            navigator('/')
                                        }}
                                    >
                                        <div className={`icon`} >
                                            <i className='pi pi-sign-out'></i>
                                        </div>
                                        <div className="link_text">{isLang === "en" ? "Logout" : "تسجيل خروج"}</div>
                                    </div>
                                </div>
                            </section>
                        }
                    </div>
                    <div className="change_lang-sidebar " dir='rtl '>
                        {
                            isLang === "en" ?
                                <div
                                    className='app__menu-talk lang_text cursor-pointer '
                                    onClick={() => {
                                        handleLanguageChange('ar')
                                        i18next.changeLanguage('ar')
                                        handleLinkClick()
                                    }}

                                >
                                    <span className=''>  عربيه</span>
                                    <img src={img.ArLang} alt="images" className='rounded- ' />
                                </div> :
                                <div
                                    className='app__menu-talk cursor-pointer '
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.8 }}
                                    onClick={() => {
                                        handleLanguageChange('en')
                                        i18next.changeLanguage('en')
                                        handleLinkClick()
                                    }}
                                >
                                    <span className='lang_text'>English</span>
                                    <img src={img.EnLang} alt="images" />
                                </div>
                        }
                    </div>
                </div>
            </Sidebar>
        </div>
    )
}

export default NavBar
