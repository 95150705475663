import axios from 'axios'
import CartFusion from 'components/Cart/CartFusion'
import img from 'constants/img'
import { FetchApi } from 'context/FetchApi'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

const Brands = () => {
  let { id } = useParams()
  let { products,brand, fetchProducts, loadingProduct, setloadingProduct } = useContext(FetchApi);
  let { t } = useTranslation()

  useEffect(() => {
    fetchProducts(6, 1, '', '', false, false, true, id);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [id]);

  return (
    <>
      { 
        loadingProduct ? <>
          <div className="app_brands">
            <div className="header">
              <h1 className='mb-3'>{brand?.BrandName}</h1>
              <h6  >{brand?.BrandDesc}</h6>
              <p>{t('EXPLORE_PRODUCTS')}</p>
            </div>
            <div className="grid mt-6 brand_prodacts" >
              <div className="lg:col-8 h-100 xl:col-8 md:col-6 sm:col-12  app_brands_list">
                <div className="grid  pt-5 d-flex  " >
                  {
                    products?.map((item, index) => (
                      <div key={index} className="lg:col-4 xl:col-4 md:col-6 sm:col-12 mt-4">
                        <CartFusion m={'m-2'}
                          id={item?.IDBrandProduct}
                          img={item.BrandProductLogo}
                          price={item?.BrandProductPrice}
                          title={item?.BrandProductTitle}
                          offer={item?.BrandProductOfferPrice}
                          path={`/details/${item?.IDBrandProduct}`}
                        />
                      </div>
                    ))
                  }
                </div>
              </div>
              <div className="lg:col-4 mb-5 xl:col-4  md:col-6 sm:col-12 flex justify-content-center align-items-start">
                <img src={brand?.BrandCover} alt="" className='w-8' />
              </div>
            </div>
          </div>
        </> :
          <div className="app_loader">
            <img src={img.logo} alt="" srcset="" />
          </div>
      }
    </>


  )
}

export default Brands
