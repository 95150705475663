import Slider from "react-slick";

import { Link } from 'react-router-dom';
import './logo_slider.scss';
const BrandsLogo = ({ data }) => {
    let settings = {
        className: "center",
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        speed: 500,
        autoplaySpeed: 2000,
        slidesToScroll: 1,
        autoplay: true,
        cssEase: "ease-out",
        arrows: false, // Set arrows to false to hide the
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <>
            <div className="slider-container mt-5"
                data-aos="flip-up"
                data-aos-duration="2000" 
            >
                <Slider {...settings}>
                    {
                        data?.map((item, index) => (
                            <div key={index} className='brand_logo '>
                                <Link to={`/brands/${item?.IDBrand}`} className=' '>
                                    <img src={item.BrandLogo} alt="" />
                                </Link>
                            </div>
                        ))
                    }

                </Slider>
            </div>
        </>
    )
}

export default BrandsLogo