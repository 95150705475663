import PageType from 'layout/PageType';
import Auth from 'layout/Auth';
import Fusion from 'layout/Fusion';
import Home from 'pages/Web/Home/Home';
import Login from 'pages/auth/Login/Login';
import Register from 'pages/auth/Register/Register';
import CheckType from 'pages/Web/CheckType';
import Slider from 'pages/Web/Home/Slider/Slider';
import BrandsLogo from 'pages/Web/Home/brandsLogos/BrandsLogo';
import Deals from 'pages/Web/Home/Deals/Deals';
import Featured from 'pages/Web/Home/Featured/Featured';
import CategoryHome from 'pages/Web/Home/CATEGORY/CategoryHome';
import Category from 'pages/Web/Category/Category';
import Brands from 'pages/Web/Brands/Brands';
import About from 'pages/Web/About/About';
import Details from 'pages/Web/Prodacts/Details'; 
import Products from 'pages/Web/Prodacts/Products';
import Orders from 'pages/Web/shop/Orders';
import { CategoryProducts } from 'pages/Web/Category/CategoryProducts';
import MyOrder from 'pages/Web/data/MyOrder/MyOrder';
import MyProfile from 'pages/Web/data/MyProfile/MyProfile';
import MyAddress from 'pages/Web/data/MyProfile/MyAddress';
import Error from 'components/NotFound/Error';
import Payment from 'pages/Web/Payment/Payment';
import NavMobile from 'components/Navbar/NavMobile';


const Component = {
    PageType,
    Auth,
    Fusion,
    Home,
    Login,
    Register,
    Slider,
    CheckType,
    BrandsLogo,
    Deals,
    Category,
    Featured,
    CategoryHome,
    Brands,
    About,
    Products,
    Orders,
    Details,
    MyOrder,
    CategoryProducts,
    MyProfile,
    MyAddress,
    Payment,
    Error,
    NavMobile

}

export default Component


      

