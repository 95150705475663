import axios from 'axios'
import img from 'constants/img'
import { LocalizationContext } from 'context/LangChange'
import { useFormik } from 'formik'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { useContext, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import '../../data/MyOrder/order.scss'

const MyProfile = () => {
  let { isLang, Profile, getdata } = useContext(LocalizationContext)
  let { t } = useTranslation()
  const [profileEdit, setProfileEdit] = useState(true)
  const [loading, setLoading] = useState(false);
  const url = `${process.env.REACT_APP_API_URL}/profile/edit`;
  const [selectedImage, setSelectedImage] = useState(null);
  const [handelOpenPassword, setHandelOpenPassword] = useState('password');

  const handleImageSelect = (event) => {
    setSelectedImage(event.target.files[0]);
  };

  const formik = useFormik({
    initialValues: {
      ClientName: Profile?.Client?.ClientName || '',
      ClientEmail: Profile?.Client?.ClientEmail || '',
      ClientPhone: Profile?.Client?.ClientPhone || '',
      ClientPhoneFlag: Profile?.Client?.ClientPhoneFlag || '',
      ClientPicture: selectedImage,
    },
    onSubmit: async (values, { resetForm }) => {

      try {
        setLoading(true);
        let { data } = await axios.post(url, values,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
          })

        if (data?.Success) {
          toast.success(t('save_mess'))
          setTimeout(() => {
            getdata()
            setLoading(false);
            setProfileEdit(true)
            resetForm();
            setSelectedImage(null)
          }, 1000);
        } else {
          setTimeout(() => {
            setLoading(false);
          }, 1000);
          toast.error(data?.ApiMsg)
        }

      } catch ({ response }) {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    }
  });

  useEffect(() => {
    formik.setValues({
      ClientName: Profile?.Client?.ClientName || '',
      ClientEmail: Profile?.Client?.ClientEmail || '',
      ClientPhone: Profile?.Client?.ClientPhone || '',
      ClientPhoneFlag: Profile?.Client?.ClientPhoneFlag || '',
      ClientPicture: selectedImage,
    });
  }, [Profile, formik.setValues, selectedImage]);

  useEffect(() => {
    getdata()
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [isLang]);

  useEffect(() => {
    formik.setFieldValue('ClientPicture', selectedImage);
  }, [selectedImage]);
  return (
    <>
      <div className="app_Order_page">
        <div className="nav_Order_page">
          <div className="left">
            <span>{t('my_profile')}</span>
          </div>
          <div className="right">
          </div>
        </div>
        <div className="grid " >
          <div className="lg:col-3 xl:col-3 md:col-6 sm:col-12 body_Order_page_left">
            <div className="body_Order_page">
              <ul>
                <li>
                  <Link to={'/profile'} className='flex align-items-center gap-3'>
                    <img src={img.User} width={25} alt="" /> <span className='title'> {t('personal_data')}  </span>
                  </Link>
                </li>
                <li>
                  <Link to={'/address'} className='flex align-items-center gap-3'>
                    <img src={img.Address} width={18} alt="" /> <span className='title'>{t('addresses')} </span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="lg:col-9 xl:col-9 md:col-6 sm:col-12 h-full  profile_form flex justify-content-center flex-column ">
            {
              profileEdit === true ?
                <div className=" mt-8 mx-8 profile_data">
                  <div className="profile">
                    {
                      Profile?.Client?.ClientPicture ?
                        <img src={Profile?.Client?.ClientPicture} width={200} height={200} style={{ borderRadius: '50%' }} alt="" /> :
                        <img src={img.defaultImg} width={240} alt="" />
                    }
                  </div>
                  <div className="content">
                    {
                      Profile?.Client?.ClientName &&
                      <p> <strong>{t('name')} </strong>  {Profile?.Client?.ClientName}  </p>
                    }
                    {
                      Profile?.Client?.ClientEmail &&
                      <p> <strong>{t('email')}  </strong> {Profile?.Client?.ClientEmail}  </p>
                    }
                    {
                      Profile?.ClientAddresses?.find(address => address.MainAddress === 1)?.ClientAddress &&
                      <p> <strong>{t('address')} </strong>{Profile?.ClientAddresses?.find(address => address.MainAddress === 1)?.ClientAddress} </p>
                    }
                    {
                      Profile?.Client?.ClientPhone &&
                      <p> <strong>{t('phone_num')} </strong>{Profile?.Client?.ClientPhone} </p>
                    }
                  </div>
                  <div className='add_cart flex  mb-8 align-item-center'>
                    <Button label={t('edit_info')} className='p-button-danger ' onClick={() => setProfileEdit(false)} />
                  </div>
                </div> :
                <form onSubmit={formik.handleSubmit} className=" mt-8 mx-8 profile_data">
                  <div className="profile">
                    {
                      formik?.values?.ClientPicture ? (
                        <img
                          src={URL.createObjectURL(selectedImage)}
                          className='object-fit-cover'
                          alt=""
                          style={{ borderRadius: '50%' }}
                          width={200} height={200}
                        />
                      ) : (
                        <img
                          src={Profile?.Client?.ClientPicture}
                          width={200} height={200}
                          className='object-fit-cover'
                          style={{ borderRadius: '50%' }}
                          alt=""
                        />
                      )
                    }
                    <label htmlFor="file-input" className="btn__porfile flex justify-content-center align-items-center" style={{ pointerEvents: 'all' }}>
                      <div className="edit cursor-pointer">
                        <i className='pi pi-pencil'></i>
                        <input
                          type="file"
                          id="file-input"
                          accept="image/*"
                          onChange={handleImageSelect}
                          style={{ display: 'none' }}
                        />
                      </div>
                    </label>

                  </div>
                  <div className="content flex gap-3 flex-column">
                  <div className="Password_open    p-input-icon-left lg:w-5 md:w-full password_profile">

                      <i className="pi pi-user" />
                      <InputText placeholder={t('placeholder_name')} onChange={formik.handleChange} onBlur={formik.handleBlur} id="ClientName" value={formik?.values?.ClientName} className='w-full p-inputtext-sm' />
                    </div>
                    <div className="Password_open    p-input-icon-left lg:w-5 md:w-full password_profile">

                      <i className="pi pi-envelope" />
                      <InputText placeholder={t('placeholder_email')} onChange={formik.handleChange} onBlur={formik.handleBlur} id="ClientEmail" value={formik?.values?.ClientEmail} className='w-full p-inputtext-sm' />
                    </div>
                    <div className="Password_open    p-input-icon-left lg:w-5 md:w-full password_profile">

                      <i className="pi pi-phone" />
                      <InputText placeholder={t('placeholder_phone_num')} onChange={formik.handleChange} onBlur={formik.handleBlur} id="ClientPhone" value={formik?.values?.ClientPhone} className='w-full p-inputtext-sm' />
                    </div>


                    <div className="Password_open    p-input-icon-left lg:w-5 md:w-full password_profile">
                      <i className="pi pi-lock" />
                      <InputText
                        id="ClientPassword"
                        name="ClientPassword"
                        type={handelOpenPassword}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        className='w-full  p-inputtext-sm'
                        dir='ltr'
                        value={formik.values.ClientPassword}
                        placeholder={t('placeholder_password')}
                      />
                      {
                        handelOpenPassword === "password" ?
                          <i className="pi pi-eye-slash app__login-btn-icons" onClick={() => setHandelOpenPassword('text')}  ></i> :
                          <i className="pi pi-eye app__login-btn-icons" onClick={() => setHandelOpenPassword('password')}  ></i>
                      }
                    </div>
                  </div>
                  <div className='add_cart flex  mb-8 align-item-center'>
                    <Button loading={loading} label={t('save_info')} className='p-button-danger ' onClick={() => setProfileEdit(false)} />
                  </div>
                </form>
            }

          </div>
        </div>
      </div>
    </>
  )
}

export default MyProfile
