import Component from 'constants/Component'
import './home.scss'
import { LocalizationContext } from 'context/LangChange';
import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { FetchApi } from 'context/FetchApi';
import img from 'constants/img';
import AOS from 'aos';

const Home = () => {

  let { isLang } = useContext(LocalizationContext);

  const url = `${process.env.REACT_APP_API_URL}/home?ClientAppLanguage=${isLang}`;
  let { setProducts } = useContext(FetchApi);

  const [data, setData] = useState(null)
  const fetchData = async () => {
    let { data } = await axios.post(`${process.env.REACT_APP_API_URL}/home?ClientAppLanguage=${isLang}`, { Type: sessionStorage.getItem('CATEGORY_TYPE') }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      }
    })
    if (data?.Success === true) {
      setData(data?.Response);
      setProducts(data?.Response?.BrandProducts)
    }
  }
  useEffect(() => {
    fetchData()
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [localStorage.getItem('i18nextLng')])
  useEffect(() => {
    AOS.init({
      once: false,
    });
    AOS.refresh();
  }, [])
  return (
    <div className='position-relative'>
      {
        data ? <>
          <Component.Slider data={data?.Advertisements} />
          <Component.BrandsLogo data={data?.Brands} />
          <Component.Deals data={data?.DealBrandProducts} />
          <Component.Featured data={data?.FeaturedBrandProducts} />
          <Component.CategoryHome data={data?.Categories} />
        </> :
          <div className="app_loader">
            <img src={img.logo} alt="" srcset="" />
          </div>
      }
    </div>
  )
}

export default Home