import img from 'constants/img'
import { Button } from 'primereact/button'
import { useTranslation } from 'react-i18next'
import './order.scss'
import { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import toast from 'react-hot-toast'
import { Dropdown } from 'primereact/dropdown'
import { Dialog } from 'primereact/dialog'
import { useFormik } from 'formik'
import { LocalizationContext } from 'context/LangChange'
import { InputTextarea } from 'primereact/inputtextarea'
const MyOrder = () => {
    let { t } = useTranslation()
    let { isLang } = useContext(LocalizationContext)

    const [orderDetailsVisibility, setOrderDetailsVisibility] = useState({});
    const [Data, setData] = useState(null);
    const [idOrder, setIDOrder] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingReturn, setLoadingReturn] = useState(false);
    const items = Array.from({ length: 200 }).map((_, i) => ({ label: `${i + 1}`, value: i + 1 }));
    const [visible, setVisible] = useState(false);

    const url = `${process.env.REACT_APP_API_URL}/orders`;
    const urlReturn = `${process.env.REACT_APP_API_URL}/orders/return`;

    async function getdata() {
        let { data } = await axios.post(url, {}, {
            headers: {
                'Content-Type': 'multipart/form-data',
                // 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            }
        })
        setData(data?.Response?.Orders);
    }

    useEffect(() => {
        getdata()
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [])

    async function cancelOrder(id) {
        setLoading(true);
        const url = `${process.env.REACT_APP_API_URL}/orders/cancel/${id}`;

        try {
            let { data } = await axios.get(url, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    // 'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                }
            })
            getdata()
            if (data?.Success === true) {

                toast.success(t('save_mess'))
            }
            setLoading(false);

        } catch (error) {
            setLoading(false);
        }
    }

    const getSeverity = (status) => {
        switch (status) {
            case 'OUT_FOR_DELIVERY':
                return t('OUT_FOR_DELIVERY');
            case 'REJECTED':
                return t('REJECTED');
            case 'DELIVERED':
                return t('DELIVERED');
            case 'ARRIVED_AT_STATION':
                return t('ARRIVED_AT_STATION');
            case 'PENDING':
                return t('pending');
            case 'ACCEPTED':
                return t('ACCEPTED');
            case 'CANCELLED':
                return t('CANCELLED');
            case 'RETURNED':
                return t('RETURNED');
        }
    };

    const toggleOrderDetails = (id) => {
        setOrderDetailsVisibility(prevState => ({
            ...prevState,
            [id]: !prevState[id]
        }));
    }

    const formik = useFormik({
        initialValues: {
            IDOrder: idOrder,
            OrderReturnReason: '',
        },
        onSubmit: async (values, { resetForm }) => {
            console.log(idOrder);
            try {
                setLoadingReturn(true);
                let { data } = await axios.post(urlReturn, {
                    IDOrder: idOrder,
                    OrderReturnReason: values?.OrderReturnReason,

                },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + localStorage.getItem('token'),
                        },
                    })

                if (data?.Success) {
                    getdata()

                    toast.success(isLang === "en" ? 'logged in successfully' : 'تم تسجيل الدخول بنجاح')
                    setTimeout(() => {
                        setLoadingReturn(false);
                        resetForm();
                        setVisible(false)
                    }, 1000);
                } else {
                    setLoadingReturn(false);
                    toast.error(data?.ApiMsg)
                    setVisible(false)

                }

            } catch ({ response }) {
                setLoadingReturn(false);
            }
        }
    });
    return (
        <>
            <div className="app_Order_page">

                <div className="nav_Order_page">
                    <div className="left">
                        <span> {t('my_order')}    </span>
                    </div>
                    <div className="right">
                    </div>
                </div>
                <div className="grid   d-flex justify-content-center   align-items-center " >
                    <div className="lg:col-9 xl:col-9 md:col-12 sm:col-12 h-full  flex justify-content-center flex-column ">
                        <div className=" mt-5">
                            <div className="grid ">
                                {
                                    Data?.map((item, index) => (
                                        <div className="lg:col-12 xl:col-12 md:col-12 sm:col-12 h-full w-100 mt-4  flex justify-content-center flex-column ">
                                            <div className="header_order">
                                                <div className="header flex justify-content-between">
                                                    <span dir='rtl'> <strong>{t('order')} : </strong> <span>{item?.OrderBatchNumber}</span> </span>
                                                    <div className='flex flex-row gap-4 align-items-center'>
                                                        <div className="price">
                                                            <span>{t('Total_price')} : {item?.OrderTotalAmount} LE</span>
                                                        </div>
                                                        <i className={`pi ${orderDetailsVisibility[item.IDOrder] ? 'pi-angle-up' : 'pi-angle-down'}`} onClick={() => toggleOrderDetails(item.IDOrder)}></i>
                                                    </div>
                                                </div>
                                                <div className="status">
                                                    <span> {t('Order_Status')} : <strong> {getSeverity(item?.OrderStatus)}</strong></span>
                                                </div>
                                                {
                                                    (item?.OrderStatus === "PENDING" || item?.OrderStatus === "ACCEPTED") &&
                                                    <div className='add_cart flex justify-content-start mt-3 align-item-center'>
                                                        <Button loading={loading} onClick={() => cancelOrder(item?.IDOrder)} label={t('cancel')} className='p-button-danger ' />
                                                    </div>
                                                }
                                                {
                                                    item?.OrderStatus === "DELIVERED" &&
                                                    <div className='add_cart flex justify-content-start mt-3 align-item-center'>
                                                        <Button loading={loading} onClick={async (e) => {
                                                            await setVisible(true)
                                                            await setIDOrder(item?.IDOrder)
                                                        }} label={t('return')} className='p-button-danger ' />
                                                    </div>
                                                }

                                                {
                                                    orderDetailsVisibility[item.IDOrder] &&
                                                    <div className="order_details">
                                                        {
                                                            item?.OrderDetails?.map((item, index) => (
                                                                <div className="content mt-3" key={index}>
                                                                    <div className="lg:col-12 xl:col-12 md:col-12 sm:col-12   ">
                                                                        <div className="item">
                                                                            <div className="grid    d-flex justify-content-start  align-items- " >
                                                                                <div className=" col-3   flex justify-content-center  align-items-center ">
                                                                                    <img src={item.BrandProductLogo} className='w-5 object-fit-contain' alt="" />
                                                                                </div>
                                                                                <div className="col-9   ">
                                                                                    <h1 > {item?.BrandProductTitle}</h1>
                                                                                    <div className="price flex flex-row gap-3  p-0 mb-3">
                                                                                        {item?.OrderDetailOfferPrice > 0 ?
                                                                                            <div className="offer_price flex justify-content-center align-items-center">
                                                                                                <span>{item?.OrderDetailPrice} LE</span>
                                                                                            </div> : ''}
                                                                                        {item?.OrderDetailOfferPrice > 0 ?
                                                                                            <div className="price_local bg">
                                                                                                <span>{item?.OrderDetailOfferPrice} LE</span>
                                                                                            </div> : <div className="price_local bg">
                                                                                                <span>{item?.OrderDetailPrice} LE</span>
                                                                                            </div>}
                                                                                    </div>
                                                                                    <div className="quntity">
                                                                                        <Dropdown
                                                                                            value={item?.OrderDetailQuantity}
                                                                                            options={items}
                                                                                            virtualScrollerOptions={{ itemSize: 38 }}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    ))
                                }


                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Dialog header={t('return')} visible={visible} onHide={() => setVisible(false)}
                style={{ width: '30vw' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }}>
                <div className="confirmation-content   flex justify-content-start  gap-3 align-items-center">
                    <form onSubmit={formik.handleSubmit} className="field col-12 md:col-12">

                        <span className="flex flex-column gap-2  w-full  mt-3">
                            <label htmlFor="OrderReturnReason" className={`${formik?.values.OrderReturnReason ? ' text-md' : ''}`}>{t('Reason')}</label>
                            <InputTextarea id="OrderReturnReason" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik?.values.OrderReturnReason} rows={5} cols={30} autoResize />
                        </span>
                        <div className='btn_save_cansel mt-4 flex gap-3 justify-content-center align-item-center'>
                            <Button label={t('cancel')} className='' type='button' onClick={() => setVisible(false)} outlined severity='' size='small' />
                            <Button loading={loadingReturn} label={t('save')} type='submit' className='p-button-danger  ' size='small' />
                        </div>
                    </form>
                </div>
            </Dialog>
        </>
    )
}

export default MyOrder
