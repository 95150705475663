import Drawer from "assets/Images/Drawer.png";
import EnLang from 'assets/Images/En.png';
import ArLang from 'assets/Images/ar.png';
import bg_logo from "assets/Images/bg_logo.png";
import clothes from "assets/Images/clothes.png";
import cosmatics from "assets/Images/cosmatics.png";
import DefaultImage from "assets/Images/default-image.jpg";
import { default as loginBg, default as logo } from "assets/Images/logo.png";
 
 
import Address from 'assets/Images/Home Address.png';
import Image_Reservations from 'assets/Images/Image_Reservations.png';
import Lipstick from 'assets/Images/Lipstick (1).png';
import Register_BG from 'assets/Images/Register background.png';
import User from 'assets/Images/User.png';
import defaultImg from 'assets/Images/default.png';
import login_BG from 'assets/Images/login background.png';
import iconType1 from 'assets/Images/types (1).png';
import iconType2 from 'assets/Images/types (2).png';
import Empty_cart from 'assets/Images/empty_cart.png';


const img = {
    loginBg,
    Lipstick,
    Empty_cart,
    Image_Reservations,
    logo,
    defaultImg,
    Address,
    User, 
    iconType1,
    iconType2, 
    DefaultImage,
    clothes, 
    Drawer,
    EnLang,
    ArLang,
    cosmatics,
    bg_logo,  
    Register_BG,
    login_BG,  
    
}

export default img;
