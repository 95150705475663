import img from 'constants/img';
import { Button } from 'primereact/button';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

const CheckType = () => {
    let Navigate = useNavigate()
    let {t} =useTranslation()
    const handelSaveType = (type) => { 
        sessionStorage.setItem('CATEGORY_TYPE', type);

        if ( sessionStorage.getItem('CATEGORY_TYPE')) {
            Navigate('/')
        }
    }
    return (
        <>
            <div className="check_page flex justify-content-center align-items-center flex-column ">
                <div className="header flex justify-content-center align-items-center flex-column">
                    <img src={img.loginBg} className='m-auto' alt="logo" />
                    <h1>Discover what you want</h1>
                    <p className='mt-3'>Absolutely Stunning Design and Functionality</p>
                </div>
                <div className="contact   ">

                    <div className='grid  '>
                        <div className='lg:col-6 xl:col-6 md:col-6 sm:col-12 mt-4  '>
                            <div className="image flex justify-content-center align-items-center">
                                <img src={img.clothes} width={450} className='w-10 ' alt="" />
                                <div className="overlay">
                                    <Button size="small" onClick={() => { handelSaveType('CLOTHES') }} label={t('apparel')} raised severity='danger' />
                                </div>
                            </div>

                        </div>
                        <div className='lg:col-6 xl:col-6 md:col-6 sm:col-12 mt-4  '>

                            <div className="image flex justify-content-center align-items-center">
                                <img src={img.cosmatics} raised width={450} className='w-10 ' alt="" />
                                <div className="overlay">
                                    <Button size="small" raised onClick={() => { handelSaveType('COSMETICS') }} label={t('cosmetics')} severity='danger' />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CheckType