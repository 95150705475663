import React, { useContext, useEffect } from 'react'
import '../Category/style.scss'
import { useTranslation } from 'react-i18next'
import { LocalizationContext } from 'context/LangChange';

const About = () => {
  let { isLang } = useContext(LocalizationContext);

  let { t } = useTranslation()
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [])

  return (
    <>
      <div className="app_about">
        <div className="about_contact" dir={isLang === "en" ? 'ltr' : 'rtl'}>
          <h1>{t('about_title')}</h1>
          <p>{t('about_desc1')}</p>
          <p>{t('about_desc2')}</p>

        </div>
      </div>
    </>
  )
}

export default About
