
import { ReactComponent as Card } from 'assets/svg/card.svg';
import { ReactComponent as Menu } from 'assets/svg/menu.svg';
import { ReactComponent as Apparel } from 'assets/svg/Apparel.svg';
import { ReactComponent as COSMETICS } from 'assets/svg/COSMETICS.svg';
import Facebook from 'assets/svg/Facebook';
import Instagram from 'assets/svg/Instagram';
import Linkedin from 'assets/svg/Linkedin';
import Twitter from 'assets/svg/Twitter';
import { ReactComponent as Brands } from 'assets/svg/darhboard_alt.svg';
import { ReactComponent as Home } from 'assets/svg/Home.svg';
import { ReactComponent as Home1 } from 'assets/svg/Home copy.svg';
import { ReactComponent as About } from 'assets/svg/aboutus.svg';
import { ReactComponent as Categories }  from 'assets/svg/Categories.svg';
import  { ReactComponent as card2 } from 'assets/svg/card2.svg';
import  { ReactComponent as Delete } from 'assets/svg/delete.svg';
import  { ReactComponent as Email } from 'assets/svg/SVG/email.svg';
import  { ReactComponent as Call } from 'assets/svg/SVG/call.svg'; 

const LogoSvg = {
    Card,
    Menu,
    Apparel,
    Facebook,
    Instagram,
    Twitter,
    Linkedin,
    COSMETICS,
    Brands,
    Home,
    About,
    Home1,
    Categories,
    card2,
    Delete,
    Email,
    Call

}

export default LogoSvg;
