import { FetchApi } from 'context/FetchApi';
import { LocalizationContext } from 'context/LangChange';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './style.scss';
import img from 'constants/img';

const Category = () => {
  let { isLang } = useContext(LocalizationContext);

  let { Category, fetchCategory } = useContext(FetchApi);
  let { t } = useTranslation()

  useEffect(() => {
    fetchCategory();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [isLang, sessionStorage.getItem('CATEGORY_TYPE')]);

  return (
    <div className='position-relative'>
      {
        Category ? <>
          <div className="app_category">
            <h1>{t('CATEGORIES')}</h1>
            <div className="grid">
              {
                Category?.map((item, index) => (
                  <div key={index} className="lg:col-4 category_page xl:col-4 md:col-6 sm:col-6 xs:col-6 flex justify-content-center align-item-center">
                    <Link className=' w-100' to={`/category/search?categoryId=${item?.IDCategory}&subCategoryId=${item?.IDSubCategory}`} >
                      <div className="Category_name mt-4">
                        <img src={item.CategoryLogo} className='w-full' alt="" />
                        <div className="overlay">
                          <div className="title">
                            <span>{item?.CategoryName}</span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))
              }
            </div>
          </div>
        </> :
          <div className="app_loader">
            <img src={img.logo} alt="" srcset="" />
          </div>
      }
    </div>


  )
}

export default Category
