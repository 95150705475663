import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const Deals = ({ data }) => {
    let { t } = useTranslation()
    return (
        <div className="app_deals  ">
            <span data-aos="flip-left"
                data-aos-duration="2000">{t('deal')}</span>
            <div className="deals mt-7" data-aos="fade-up"
                data-aos-duration="2000">
                <div className="grid  deal_grid">
                    {
                        data?.map((item, index) => (
                            <div data-aos="fade-up"
                                data-aos-duration={1000 * (index + 1)} key={index} className="lg:col-4 xl:col-4 md:col-6   sm:col-6 xs:col-6 flex justify-content-center align-items-center ">
                                <Link className='w-100  h-100 mt-3 p-0   flex justify-content-center align-items-center' to={`/details/${item.IDBrandProduct}`}>
                                    <img src={item.BrandProductDealImage} className='w-100 m-0 p-0' />
                                </Link>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>


    )
}

export default Deals