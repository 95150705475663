import axios from 'axios';
import { addToCart } from 'helper/cartApis';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Galleria } from 'primereact/galleria';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import './prodacts.scss';
import toast from 'react-hot-toast';
import { FetchApi } from 'context/FetchApi';

const Details = () => {
  let { id } = useParams()
  const url = ``;

  let { getCart } = useContext(FetchApi);

  let { t } = useTranslation()
  const [images, setImages] = useState(null);
  const responsiveOptions = [
    {
      breakpoint: '991px',
      numVisible: 4
    },
    {
      breakpoint: '767px',
      numVisible: 3
    },
    {
      breakpoint: '575px',
      numVisible: 3
    }
  ];

  const itemTemplate = (item) => {
    if (item?.BrandProductLogo) {
      return <img src={item.BrandProductLogo} className='galleria_img object-fit-contain' alt={item.alt} style={{ width: '100%', display: 'block' }} />;
    } else {
      return <img src={item.BrandProductGalleryPath} className='galleria_img object-fit-contain' alt={item.alt} style={{ width: '100%', display: 'block' }} />;
    }

  }

  const thumbnailTemplate = (item) => {
    console.log(item);
    if (item?.BrandProductLogo) {
      return <img src={item?.BrandProductLogo} alt={item.alt} style={{ display: 'block' }} />;
    } else {
      return <img src={item.BrandProductGalleryPath} alt={item.alt} style={{ display: 'block' }} />;
    }
  }
  const [selectedItem, setSelectedItem] = useState(null);
  const onItemChange = (e) => {
    setSelectedItem(e.value);
  }

  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)

  const fetchData = async () => {
    let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/products/details/${id}?ClientAppLanguage=${localStorage.getItem('i18nextLng')}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      }
    })
    if (data?.Success === true) {
      setData(data?.Response);
      setImages([data?.Response, ...data?.Response?.BrandProductGallery])
    }
  }
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    fetchData()
  }, [localStorage.getItem('i18nextLng')])


  const items = Array.from({ length: 200 }).map((_, i) => ({ label: `${i + 1}`, value: i + 1 }));
  const addCart = () => {
    setLoading(true)
    addToCart(id, selectedItem).then(async (data) => {
      await getCart()
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      toast.success(t('save_mess'))
    })
  }

  return (
    <div className="">
      <div className="app_prodact_details">
        <div className="grid">
          <div className="lg:col-6 xl:col-6 md:col-6 sm:col-12  ">
            <div className="gallery_lg_Device">
              <Galleria
                value={images}
                thumbnailsPosition='left'
                responsiveOptions={responsiveOptions}
                numVisible={5}
                circular
                style={{ maxWidth: '100%' }}
                showItemNavigatorsOnHover
                item={itemTemplate}
                autoPlay
                thumbnail={thumbnailTemplate} />
            </div>

          </div>
          <div className="lg:col-6 xl:col-6 md:col-6 sm:col-12 p-2 px-6 content_product_sm  flex justify-content-center align-items-center ">
            <div className="content_product w-full">
              <h1>{data?.BrandProductTitle}</h1>
              <p>{data?.BrandProductDesc}</p>
              <div className="price flex flex-row gap-3">
                {
                  data?.BrandProductOfferPrice > 0 ?
                    <div className="offer_price flex justify-content-center align-items-center">
                      <span>{data?.BrandProductPrice} LE</span>
                    </div> : ''
                }
                {
                  data?.BrandProductOfferPrice > 0 ?
                    <div className="price_local bg">
                      <span>{data?.BrandProductOfferPrice} LE</span>
                    </div> : <div className="price_local bg">
                      <span>{data?.BrandProductPrice} LE</span>
                    </div>
                }
              </div>
              <div className="quntity">
                <h5> </h5>
                <Dropdown value={selectedItem} options={items} onChange={onItemChange} virtualScrollerOptions={{ itemSize: 38 }} placeholder={t('Quantity')} />
              </div>
              <div className='add_cart  mt-4 '>
                <Button loading={loading} onClick={addCart} label={t('add_cart')} size=' ' className='p-button-danger ' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Details