import axios from "axios";
//  Add product to cart
export const addToCart = async (id, selectedItem) => {
    const url = `${process.env.REACT_APP_API_URL}/cart/add?ClientAppLanguage=${localStorage.getItem('i18nextLng')}`;
    let { data } = await axios.post(url,
        {
            IDCart: Number(localStorage.getItem('IDCart')),
            IDBrandProduct: id,
            Quantity: selectedItem
        },
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            }
        })
    if (data?.Success === true) {
        localStorage.setItem('IDCart', data?.Response)
        return data
    }
}

// Get product to cart 
export const getCart = async () => {
    const url = `${process.env.REACT_APP_API_URL}/cart?ClientAppLanguage=${localStorage.getItem('i18nextLng')}`;
    let { data } = await axios.post(url, { IDCart:  Number(localStorage.getItem('IDCart')) > 0 ? Number(localStorage.getItem('IDCart')) : '',
},
    {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            }
        })
if (data?.Success === true) {
    localStorage.setItem('IDCart', data?.Response?.IDCart)
    return data
}
}

// Edit product in cart 
export const editCart = async (id, items) => {
    const url = `${process.env.REACT_APP_API_URL}/cart/edit?ClientAppLanguage=${localStorage.getItem('i18nextLng')}`;
    let { data } = await axios.post(url,
        {
            IDCart: localStorage.getItem('IDCart'),
            IDCartItem: id,
            Quantity: items
        },
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            }
        })
    if (data?.Success === true) {
        return data
    }
}

// Delete product in cart 
export const deleteCarts = async (id) => {
    const url = `${process.env.REACT_APP_API_URL}/cart/remove?ClientAppLanguage=${localStorage.getItem('i18nextLng')}`;
    let { data } = await axios.post(url,
        {
            IDCartItem: id,
            IDCart: localStorage.getItem('IDCart'),

        },
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            }
        })
    if (data?.Success === true) {
        return data
    }
}