import axios from 'axios';
import { createContext, useEffect, useState } from 'react';

export const FetchApi = createContext([]);

function FetchApiContext({ children }) {

  let isLang = localStorage.getItem('i18nextLng');


  const [cartNum, setCartsNum] = useState(0);
  const [country, setCountry] = useState([]);
  const [city, setCity] = useState([]);
  const [area, setArea] = useState([]);
  const [products, setProducts] = useState([])
  const [brands, setBrands] = useState([])
  const [brand, setBrand] = useState(null)
  const [Category, setCategory] = useState(null)
  const [subCategory, setSubCategory] = useState(null)
  const [active, setActive] = useState(null)
  const [footerData, setFooterData] = useState(null)
  const [subCategoryLength, setSubCategoryLength] = useState(0)
  const [loadingProduct, setloadingProduct] = useState(false)

  const fetchBrand = async () => {
    let { data } = await axios.post(`${process.env.REACT_APP_API_URL}/brands?ClientAppLanguage=${localStorage.getItem('i18nextLng')}`, { Type: sessionStorage.getItem('CATEGORY_TYPE') }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      }
    })
    if (data?.Success === true) {
      setBrands(data?.Response);
    }
  }


  const fetchFooter = async () => {
    let { data } = await axios.post(`${process.env.REACT_APP_API_URL}/footer?ClientAppLanguage=${localStorage.getItem('i18nextLng')}`, { Type: sessionStorage.getItem('CATEGORY_TYPE') }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      }
    })
    if (data?.Success === true) {
      setFooterData(data?.Response);

    }
  };


  const fetchCategory = async () => {
    let { data } = await axios.post(`${process.env.REACT_APP_API_URL}/categories?ClientAppLanguage=${localStorage.getItem('i18nextLng')}`, { Type: sessionStorage.getItem('CATEGORY_TYPE') }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      }
    })
    if (data?.Success === true) {
      setCategory(data?.Response);
    }
  }
  const fetchSubCategory = async (id) => {
    setActive(null)
    let { data } = await axios.post(`${process.env.REACT_APP_API_URL}/subcategories?ClientAppLanguage=${localStorage.getItem('i18nextLng')}`, { IDCategory: id }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      }
    })
    if (data?.Success === true) {
      setActive(data?.Response?.at(0)?.IDSubCategory);
      setSubCategory(data?.Response);
      setSubCategoryLength(data?.Response.length);
    }
  }
  const fetchProducts = async (itemNum, pageNum, idCat, idSubCat, category = true, SubCategory = true, Brand = true, IDBrand, search = true, searchData) => {
    setloadingProduct(false)
    let formData = {
      NumberPerPage: itemNum,
      page: pageNum,
      Type: sessionStorage.getItem('CATEGORY_TYPE')
    }
    if (category === true) {
      formData.IDCategory = Number(idCat)
    } else if (SubCategory === true) {
      formData.IDSubCategory = Number(idSubCat)
    } else if (Brand === true) {
      formData.IDBrand = Number(IDBrand)
    } else if (search == true) {
      formData.SearchKey = searchData
    }


    let { data } = await axios.post(`${process.env.REACT_APP_API_URL}/products?ClientAppLanguage=${localStorage.getItem('i18nextLng')}`, formData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      }
    })
    if (data?.Success === true) {
      setloadingProduct(true) 
      setProducts(data?.Response?.BrandProducts);
      setBrand(data?.Response?.Brand);
      console.log(data?.Response?.Brand);
    }
  }

  async function fetchCountry() {
    let { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/countries?ClientAppLanguage=${localStorage.getItem('i18nextLng')}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      }
    );
    setCountry(data?.Response);
  }
  async function fetchCity(id) {
    if (id) {
      let { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/cities/${id}?ClientAppLanguage=${localStorage.getItem('i18nextLng')}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        }
      );
      setCity(data?.Response);
    }
  }
  async function fetchArea(id) {
    if (id) {
      let { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/areas/${id}?ClientAppLanguage=${localStorage.getItem('i18nextLng')}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        }
      );
      setArea(data?.Response);
    }
  }

  const getCart = async () => {
    const url = `${process.env.REACT_APP_API_URL}/cart`;
    let { data } = await axios.post(url, { IDCart: Number(localStorage.getItem('IDCart')) },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        }
      })

    setCartsNum(data?.Response?.CartItems?.length);
    if (data?.Success === false) {
      localStorage.removeItem('IDCart')
    }
  }

  useEffect(() => {
    getCart()
    fetchBrand()
  }, [localStorage.getItem('i18nextLng'),sessionStorage.getItem('CATEGORY_TYPE')])


  return (
    <>
      <FetchApi.Provider
        value={{
          Category,
          fetchCategory,
          active,
          footerData,
          fetchProducts,
          products,
          fetchFooter,
          fetchCountry,
          subCategoryLength,
          setActive,
          fetchCity,
          fetchArea,
          loadingProduct,setloadingProduct,
          country,
          city,
          area,
          getCart,
          cartNum,
          setCartsNum,
          brand,
          subCategory,
          fetchSubCategory,
          setProducts,
          brands
        }}
      >
        {children}
      </FetchApi.Provider>
    </>
  );
}

export default FetchApiContext;
