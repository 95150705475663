import axios from 'axios'
import img from 'constants/img'
import { LocalizationContext } from 'context/LangChange'
import { useFormik } from 'formik'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { Password } from 'primereact/password'
import { useContext, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import '../auth.scss'
import { Dropdown } from 'primereact/dropdown'
import { FetchApi } from 'context/FetchApi'

const Register = () => {
  let { isLang } = useContext(LocalizationContext)
  // const url = ;
  let { city, area, fetchCity, fetchArea } = useContext(FetchApi)
  const [handelOpenPassword, setHandelOpenPassword] = useState('password');

  let { t } = useTranslation()
  const [loading, setLoading] = useState(false);

  let navigate = useNavigate()

  const formik = useFormik({
    initialValues: {
      ClientName: '',
      LoginBy: 'MANUAL',
      ClientEmail: '',
      ClientPhone: '',
      ClientPhoneFlag: '+20',
      ClientPassword: '',
      Address: '',
      AddressName: ''
    },
    onSubmit: async (values, { resetForm }) => {
      try {
        setLoading(true);
        let { data } = await axios.post(`${process.env.REACT_APP_API_URL}/register?ClientAppLanguage=${isLang}`, values,
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
          })

        if (data?.Success) {
          toast.success(isLang === "en" ? 'The account has been created' : 'تم انشاء الحساب ')
          setTimeout(() => {
            setLoading(false);
            navigate('/login')
            resetForm();
          }, 1000);
        } else {
          setLoading(false);
          toast.error(data?.ApiMsg)
        }

      } catch ({ response }) {
        setLoading(false);
      }
    }
  });

  useEffect(() => {
    fetchCity(1)
    return () => {
      fetchCity(1)

    }
  }, [isLang])

  return (
    <>

      <div className="app_register" dir='ltr'>
        <div className="register_form">
          <div className="logo">
            <img src={img.bg_logo} alt="" width={200} />
          </div>
          <form onSubmit={formik.handleSubmit} className="field col-12 md:col-12" dir={isLang === "en" ? 'ltr' : 'rtl'}>
            <span className={`p-float-label ${isLang === "en" ? 'p-input-icon-left' : 'p-input-icon-right'}  w-full mt-3`} >
              <i className="pi pi-user" />
              <InputText required onChange={formik.handleChange} placeholder={t('full_name')} onBlur={formik.handleBlur} id="ClientName" value={formik?.values.ClientName} className='w-full  p-inputtext-sm' />
            </span>
            <span className={`p-float-label ${isLang === "en" ? 'p-input-icon-left' : 'p-input-icon-right'}  w-full mt-3`} >
              <i className="pi pi-envelope" />
              <InputText required type='email' onChange={formik.handleChange} placeholder={t('Email_address')} onBlur={formik.handleBlur} value={formik?.values.ClientEmail} id="ClientEmail" className='w-full  p-inputtext-sm' />
            </span>


            <div className={` Password_open p-float-label  w-full mt-3 ${isLang === "en" ? 'p-input-icon-left' : 'p-input-icon-right'}  `} >

              <i className="pi pi-lock" />
              <InputText
                id="ClientPassword"
                name="ClientPassword"
                type={handelOpenPassword}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                className='w-full p-inputtext-sm'
                placeholder={t('password')} 
                value={formik.values.ClientPassword}

              />
              {
                handelOpenPassword === "password" ?
                  <i className="pi pi-eye-slash app__login-btn-icons" onClick={() => setHandelOpenPassword('text')}  ></i> :
                  <i className="pi pi-eye app__login-btn-icons" onClick={() => setHandelOpenPassword('password')}  ></i>
              }
            </div>


            <span className={`p-float-label ${isLang === "en" ? 'p-input-icon-left' : 'p-input-icon-right'}  w-full mt-3`} >
              <i className="pi pi-phone" />
              <InputText required id="ClientPhone" onChange={formik.handleChange} placeholder={t('Phone_no')} onBlur={formik.handleBlur} value={formik?.values.ClientPhone} className='w-full  p-inputtext-sm' />
            </span>
            <span className=" p-input-icon-left w-full mt-3 bg-black ">
              <i className="pi pi-map" />
              <Dropdown filter
                options={city?.map(item => ({
                  name: item.CityName,
                  value: item.IDCity,
                }))}
                id="IDCity"
                name="IDCity"
                optionLabel="name"
                optionValue="value"
                value={formik.values.IDCity} // Add this line 
                onChange={(e) => {
                  formik.setFieldValue("IDCity", e.value)
                  fetchArea(e.value)
                }}
                onBlur={formik.handleBlur}
                placeholder={t('City')}

                className="w-full p-inputtext-sm"
              />

            </span>
            <span className=" p-input-icon-left w-full mt-3 bg-black "  >
              <i className="pi pi-map" />
              <Dropdown filter
                options={area?.map(item => ({
                  name: item.AreaName,
                  value: item.IDArea,
                }))}
                id="IDArea"
                name="IDArea"
                optionLabel="name"
                optionValue="value"
                value={formik.values.IDArea} // Add this line 
                onChange={(e) => {
                  formik.setFieldValue("IDArea", e.value)

                }}
                onBlur={formik.handleBlur}
                placeholder={t('area')}

                className="w-full p-inputtext-sm"
              />
            </span>
            <span className={`p-float-label ${isLang === "en" ? 'p-input-icon-left' : 'p-input-icon-right'}  w-full mt-3`} >
              <i className="pi pi-map" />
              <InputText required id="Address" placeholder={t('address')} onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik?.values.Address} className='w-full  p-inputtext-sm' />
            </span>
            <span className={`p-float-label ${isLang === "en" ? 'p-input-icon-left' : 'p-input-icon-right'}  w-full mt-3`} >
              <i className="pi pi-map-marker" />
              <InputText id="AddressName" placeholder={t('name_address')} onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik?.values.AddressName} className='w-full  p-inputtext-sm' />
            </span>

            <div className='add_cart mt-4 flex justify-content-center align-item-center'>
              <Button loading={loading} type='submit' label={t('SignUpBtn')} className='p-button-danger  w-5' />
            </div>
            <div className='flex justify-content-center  mt-3'>
              <span className='text-white'>{t('Do_account')} <Link to="/login" className='SignUp'>{t('LOGIN')}</Link> </span>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default Register