import Footer from 'components/Footer/Footer'
import NavBar from 'components/Navbar/NavBar'
import LogoSvg from "../constants/logo.js";
import React from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'
import Component from 'constants/Component.js';

const Fusion = () => {

  return (
    <div className='mobile_responsive'>
      <NavBar />
      <Outlet></Outlet>
      <Footer />

      <Component.NavMobile/>
 
    </div>
  )
}

export default Fusion