import img from 'constants/img';
import { LocalizationContext } from 'context/LangChange';

import { Galleria } from 'primereact/galleria';
import React, { useContext } from 'react';
import './slider.scss'
import { Link } from 'react-router-dom';
const Slider = ({ data }) => {
    let { isLang } = useContext(LocalizationContext);

    const itemTemplate = (item) => {
        return (
            <div className='image__container'>
                <Link className='w-100 h-100' to={`${item?.LinkType === "CATEGORY" ? `/category/search?categoryId=${item?.IDLink}&subCategoryId=0` :
                    item?.LinkType === "BRAND" ? `/brands/${item?.IDLink}` :
                        item?.LinkType === "BRAND_PRODUCT" ? `/details/${item?.IDLink}` :
                            ''}`}>
                    <img src={item.AdvertisementImage} alt={'Advertisement Image'} className='image__slider' loading='lazy' />
                    <div className="overlay"></div>
                </Link>
            </div>
        );
    };
    return (
        <div style={{ width: '100%' }} className='overflow-hidden'>
            <Galleria value={data}
                showThumbnails={false}
                className="image-slider-indicators"
                showIndicatorsOnItem={true}
                circular autoPlay transitionInterval={2000} 
                showIndicators item={itemTemplate} />
        </div>
    );
}

export default Slider
