import img from 'constants/img';
import LogoSvg from 'constants/logo';
import { FetchApi } from 'context/FetchApi';
import { LocalizationContext } from 'context/LangChange';
import { motion } from 'framer-motion';
import { useContext, useEffect } from 'react';
import { Nav } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import './footer.scss';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';


const Footer = () => {
  let { isLang } = useContext(LocalizationContext);
  let { footerData, fetchFooter } = useContext(FetchApi);
  const location = useLocation();
  let { t } = useTranslation()
  useEffect(() => {
    fetchFooter()
  }, [localStorage.getItem('i18nextLng')])

  return (
    <div>
      <div className="app_contacts">
        <div className="body grid justify-content-between align-items-center">
          <div className='lg:col-7 xl:col-7 md:col-12 sm:col-12    '>
            <h1>{t('footer_title')}</h1>
            <p className='p-0 m-0'>{t('footer_desc')}</p>
          </div>

          <div className='   lg:col-5 xl:col-5 md:col-12 sm:col-12   pt-5'>
            <div className="grid contect">
              <div className='lg:col-6 xl:col-6 md:col-12 sm:col-12  '>
                <div className='flex gap-2 align-items-center'>
                  <LogoSvg.Email />
                  <span>
                    <a href={`mailto:${footerData?.GeneralSettings?.at(0)?.GeneralSettingValue}`}>{footerData?.GeneralSettings?.at(0)?.GeneralSettingValue}</a>
                  </span>
                </div>
              </div>
              <div className='lg:col-6 xl:col-6 md:col-12 sm:col-12  '>
                <div className='flex gap-2 align-items-center'>
                  <LogoSvg.Call />
                  <span>
                    <a href={`tel:+${footerData?.GeneralSettings?.at(1)?.GeneralSettingValue}`}>{footerData?.GeneralSettings?.at(1)?.GeneralSettingValue}</a>
                  </span>
                </div>
              </div>
              <div className='lg:col-6 xl:col-6 md:col-12 sm:col-12 xs:col-12 pt-3'>
                <div className='flex gap-2 align-items-center  '>
                  <LogoSvg.Home />
                  <span>{footerData?.GeneralSettings?.at(2)?.GeneralSettingValue}</span>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div className="app__footer pt-40  overflow-hidden" dir={isLang === "en" ? 'ltr' : 'rtl'}>
        <div className='container'>
          <div className="grid ">
            <div className='lg:col-6 xl:col-6 md:col-12 sm:col-12 mt-4 order-md-4 order-lg-0 order-sm-4 footer_lg flex justify-content-start mt-7 align-items-center flex-column gap-5'>
              <img src={img.bg_logo} width={200} alt="" />
              <div className={`social  `}>
                <motion.a
                  href={'https://twitter.com/'}
                  target='_blank' rel="noreferrer"
                  whileHover={{ scale: 1.2 }}
                  transition={{ duration: 0.3 }}
                >
                  <LogoSvg.Twitter color={'#D42A1F'} />
                </motion.a>

                <motion.a
                  href={'https://www.facebook.com/'}
                  target='_blank' rel="noreferrer"
                  whileHover={{ scale: 1.2 }}
                  transition={{ duration: 0.3 }}
                >
                  <LogoSvg.Facebook color={'#D42A1F'} className='facebook' />
                </motion.a>

                <motion.a
                  href={'https://www.instagram.com/'}
                  target='_blank' rel="noreferrer"
                  whileHover={{ scale: 1.2 }}
                  transition={{ duration: 0.3 }}
                >
                  <LogoSvg.Instagram color={'#D42A1F'} />
                </motion.a>
              </div>
            </div>
            <div className='lg:col-6 xl:col-6  md:col-12 sm:col-12 flex flex-row gap-sm-8'>
              <div className='lg:col-6 xl:col-6 md:col-12 sm:col-12 mt-4  order-md-2 order-lg-2 order-sm-2' >
                <div className='footer_Links' >
                  <h1> {isLang === "en" ? 'Top Brands' : 'روابـــط'} </h1>
                  <div className="footer_ui">
                    <ul>
                      {
                        footerData?.Brands?.map((item, index) => (
                          <li> <Nav.Link as={Link} to={`/brands/${item?.IDBrand}`} className={`nav-link ${location.pathname === '/' ? '  active_main_color' : ''}`}>  {item?.BrandName}</Nav.Link> </li>
                        ))
                      }
                    </ul>
                  </div>
                </div>
              </div>
              <div className='lg:col-6 xl:col-6 md:col-12 sm:col-12 mt-4 order-md-3 order-lg-3 order-sm-3'  >
                <div className='footer_Links' >
                  <h1> {isLang === "en" ? 'Top Categories  ' : 'روابـــط إضافية'} </h1>
                  <div className="footer_ui">
                    <ul >
                      {
                        footerData?.Categories?.map((item, index) => (
                          <li> <Nav.Link as={Link} to={`/category/search?categoryId=${item?.IDCategory}&subCategoryId=${item?.IDSubCategory}`} className={`nav-link ${location.pathname === '/' ? '  active_main_color' : ''}`}>  {item?.CategoryName}</Nav.Link> </li>
                        ))
                      }
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className='hr__footer' />
          <div className="footer__buttom pb-3  flex justify-content-center align-items-center">
            <span className='text-center'>Copyright © 2024, Vigtas.com All Rights Reserved</span>
          </div>
        </div>
      </div>
    </div >
  )
}

export default Footer
