import axios from 'axios';
import img from 'constants/img';
import { LocalizationContext } from 'context/LangChange';
import { Button } from 'primereact/button';
import { RadioButton } from 'primereact/radiobutton';
import { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import '../../data/MyOrder/order.scss';
import { Dialog } from 'primereact/dialog';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { FetchApi } from 'context/FetchApi';

const MyAddress = () => {

    let { city, area, fetchCity, fetchArea } = useContext(FetchApi)
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [visibleEdit, setVisibleEdit] = useState(false);
    const [loadingEdit, setLoadingEdit] = useState(false);
    const [data, setData] = useState({});
    const url = `${process.env.REACT_APP_API_URL}/address/add`;

    let { isLang, Profile, getdata } = useContext(LocalizationContext)
    let { t } = useTranslation()

    const ChangeMainAddres = async (id) => {
        await axios.get(`${process.env.REACT_APP_API_URL}/address/main/${id?.IDClientAddress}`, {
            headers: {
                'Content-Type': 'application/json',
                // 'Content-Type': 'multipart/form-data', 
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
        }).then(({ data }) => {
            toast.success(t('save_mess'))
            getdata()
            window.scroll(0, 0)
        })
    }

    const deleteAddres = async (id) => {
        await axios.get(`${process.env.REACT_APP_API_URL}/address/delete/${id?.IDClientAddress}`, {
            headers: {
                'Content-Type': 'application/json',
                // 'Content-Type': 'multipart/form-data', 
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
        }).then(({ data }) => {

            toast.success(t('save_mess'))
            getdata()
            window.scroll(0, 0)
        })
    }
    const formik = useFormik({
        initialValues: {
            Address: '',
            AddressName: '',
            AddressPhone: '',
            IDCity: '',
            IDArea: ''

        },
        onSubmit: async (values, { resetForm }) => {

            try {
                setLoading(true);
                let { data } = await axios.post(url, {
                    Address: values?.Address,
                    AddressName: values?.AddressName,
                    AddressPhone: values?.AddressPhone,
                    IDArea: values?.IDArea,
                },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + localStorage.getItem('token'),
                        },
                    })

                if (data?.Success) {
                    getdata()

                    toast.success(isLang === "en" ? 'logged in successfully' : 'تم تسجيل الدخول بنجاح')
                    setTimeout(() => {
                        setLoading(false);
                        resetForm();
                        setVisible(false)
                    }, 1000);
                } else {
                    setLoading(false);
                    toast.error(data?.ApiMsg)
                    setVisible(false)

                }

            } catch ({ response }) {
                setLoading(false);
            }
        }
    });
    const address = async () => {
        await fetchCity(1)
        await fetchArea(1)
        await formik.setFieldValue('IDCity', 1)
        await formik.setFieldValue('IDArea', 1)

    }
    useEffect(() => {
        address()
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [isLang])

    const updateAddress = (item) => {
        setData(item)
        console.log(item);
        setVisibleEdit(true)
    }


    const formikEdit = useFormik({
        initialValues: {
            AddressName: '',
            ClientAddress: '',
            AddressPhone: '',
            IDCity: '',
            IDClientAddress: '', // Ensure 'data' here is coming from a higher scope or state
            IDArea: ''
        },
        onSubmit: async (values, { resetForm }) => {
            console.log(values);
            setLoading(true);
            
            let { data: responseData } = await axios.post(`${process.env.REACT_APP_API_URL}/address/edit`, {
                AddressName: values?.AddressName,
                Address: values?.ClientAddress,
                AddressPhone: values?.AddressPhone,
                IDArea: values?.IDArea,
                IDClientAddress: values?.IDClientAddress, // Use the value from formik's state
            },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    },
                });
    
            if (responseData?.Success) {
                getdata();
                toast.success(isLang === "en" ? 'Update successfully' : 'تم تسجيل الدخول بنجاح');
                setTimeout(() => {
                    setLoadingEdit(false);
                    resetForm();
                    setVisibleEdit(false);
                }, 1000);
            } else {
                setLoadingEdit(false);
                toast.error(responseData?.ApiMsg);
                setVisibleEdit(false);
            }
        }
    });
    

    useEffect(() => {
        formikEdit.setValues({
            AddressName: data?.AddressName,
            ClientAddress: data?.ClientAddress,
            IDClientAddress: data?.IDClientAddress,
            AddressPhone: data?.AddressPhone,
            IDCity: data?.IDCity,
            IDArea: data?.IDArea
        });
    }, [data, formikEdit.setValues]);


    return (
        <>
            <div className="app_Order_page">
                <div className="nav_Order_page">
                    <div className="left">
                        <span>{t('my_profile')}</span>
                    </div>
                    <div className="right">
                    </div>
                </div>
                <div className="grid    " >
                    <div className="lg:col-3 xl:col-3 md:col-6 sm:col-12 body_Order_page_left">
                        <div className="body_Order_page">
                            <ul>
                                <li>
                                    <Link to={'/profile'} className='flex align-items-center gap-3'>
                                        <img src={img.User} width={25} alt="" /> <span> {t('personal_data')}  </span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'/address'} className='flex align-items-center gap-3'>
                                        <img src={img.Address} width={18} alt="" /> <span>{t('addresses')} </span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="    lg:col-9  mt-5 xl:col-9 md:col-6 sm:col-12 h-full  flex justify-content-center flex-column client_myddresses   ">
                        {
                            Profile?.ClientAddresses?.sort((a, b) => (b.MainAddress === 1) - (a.MainAddress === 1)).map((item, index) => (
                                <div className="app_my_address  mt-4">
                                    <div className="flex align-items-center gap-2">
                                        <RadioButton inputId="ingredient1" name="pizza" value="1" onChange={(e) => ChangeMainAddres(item)} checked={item?.MainAddress === 1} />
                                        <label htmlFor="ingredient1" className="address_title ml-2">{item?.MainAddress === 1 ?  t('main_address') : `${t('address')} ${index + 1}`}</label>
                                    </div>
                                    <div className="grid  mt-3" >
                                        <div className="grid col-9 content_contect" >
                                            <div className="lg:col-6 xl:col-6 md:col-6 sm:col-12">
                                                <div className='  content_contect-text'> <strong >{t('name')}</strong>  <span>{item?.AddressName}</span>  </div>
                                            </div>
                                            <div className="lg:col-6 xl:col-6 md:col-6 sm:col-12">
                                                <div className='  content_contect-text'> <strong> {t('contact')}  </strong>{item?.AddressPhone}</div>
                                            </div>
                                            <div className="lg:col-12 xl:col-12 md:col-12 sm:col-12">
                                                <div className='  content_contect-text'> <strong>{t('address')}</strong>  {item?.ClientAddress}</div>
                                            </div>
                                        </div>
                                        <div className="lg:col-3 gap-2 xl:col-3 md:col-3 sm:col-4 add_cart_addres flex justify-content-end ">
                                            <div className='add_cart flex  mb-6 align-item-center'>
                                                <Button label={t('remove')} onClick={(e) => updateAddress(item)} className='p-button-danger ' />
                                            </div>
                                            <div className='add_cart3 flex  mb-6 align-item-center'>
                                                <Button icon="pi pi-trash" onClick={(e) => deleteAddres(item)} className='p-button-danger ' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                        <div className="lg:col-12 xl:col-12 md:col-12 sm:col-4  flex justify-content-end mt-4">
                            <div className='add_address flex  mb-6 align-item-center'>
                                <Button icon="pi pi-plus" onClick={(e) => setVisible(true)} className='p-button-danger ' />
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <Dialog header={t('add_address')} visible={visible} onHide={() => setVisible(false)}
                style={{ width: '30vw' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }}>
                <form onSubmit={formik.handleSubmit} className="field col-12 md:col-12">
                    <span className="flex flex-column gap-2  w-full  mt-3">
                        <label htmlFor="AddressName" className={`${formik?.values.AddressName ? ' text-md' : ''}`}>{t('name')}</label>
                        <InputText id="AddressName" className='w-full  p-inputtext-sm' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik?.values.AddressName} />
                    </span>
                    <span className="flex flex-column gap-2  w-full  mt-3">
                        <label htmlFor="AddressPhone" className={`${formik?.values.AddressPhone ? ' text-md' : ''}`}>{t('phone_num')}</label>
                        <InputText id="AddressPhone" className='w-full  p-inputtext-sm' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik?.values.AddressPhone} />
                    </span>
                    <span className="flex flex-column gap-2  w-full  mt-3">
                        <label htmlFor="Address" className={`${formik?.values.Address ? ' text-md' : ''}`}>{t('city')}</label>
                        <Dropdown filter
                            options={city?.map(item => ({
                                name: item.CityName,
                                value: item.IDCity,
                            }))}
                            id="IDCity"
                            name="IDCity"
                            optionLabel="name"
                            optionValue="value"
                            value={formik.values.IDCity} // Add this line 
                            onChange={(e) => {
                                formik.setFieldValue("IDCity", e.value)
                                fetchArea(e.value)
                            }}
                            onBlur={formik.handleBlur}
                            placeholder={'City'}
                            className="w-full p-inputtext-sm"
                        />
                    </span>
                    <span className="flex flex-column gap-2  w-full  mt-3">
                        <label htmlFor="Address" className={`${formik?.values.Address ? ' text-md' : ''}`}>{t('area')}</label>
                        <Dropdown filter
                            options={area?.map(item => ({
                                name: item.AreaName,
                                value: item.IDArea,
                            }))}
                            id="IDArea"
                            name="IDArea"
                            optionLabel="name"
                            optionValue="value"
                            value={formik.values.IDArea} // Add this line 
                            onChange={(e) => {
                                formik.setFieldValue("IDArea", e.value)

                            }}
                            onBlur={formik.handleBlur}
                            placeholder={'Area'}

                            className="w-full p-inputtext-sm"
                        />
                    </span>
                    <span className="flex flex-column gap-2  w-full  mt-3">
                        <label htmlFor="Address" className={`${formik?.values.Address ? ' text-md' : ''}`}>{t('address')}</label>
                        <InputTextarea id="Address" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik?.values.Address} rows={5} cols={30} autoResize />
                    </span>
                    <div className='btn_save_cansel mt-4 flex gap-3 justify-content-center align-item-center'>
                        <Button label={t('cancel')} className='' type='button' onClick={() => setVisible(false)} outlined severity='' size='small' />
                        <Button loading={loading} label={t('save')} type='submit' className='p-button-danger  ' size='small' />
                    </div>


                </form>
            </Dialog>


            <Dialog header={t('edit_address')} visible={visibleEdit} onHide={() => setVisibleEdit(false)}
                style={{ width: '30vw' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }}>
                <form onSubmit={formikEdit.handleSubmit} className="field col-12 md:col-12">
                    <span className="flex flex-column gap-2   w-full  mt-3 ">
                        <label htmlFor="AddressName" className={`${formikEdit?.values.AddressName ? ' text-md' : ''}`}>{t('name')}</label>
                        <InputText id="AddressName" className='w-full  p-inputtext-sm' onChange={formikEdit.handleChange} onBlur={formikEdit.handleBlur} value={formikEdit?.values.AddressName} />
                    </span>
                    <span className="flex flex-column gap-2  w-full  mt-3">
                        <label htmlFor="AddressPhone" className={`${formikEdit?.values.AddressPhone ? ' text-md' : ''}`}>{t('phone_num')}</label>
                        <InputText id="AddressPhone" className='w-full  p-inputtext-sm' onChange={formikEdit.handleChange} onBlur={formikEdit.handleBlur} value={formikEdit?.values.AddressPhone} />
                    </span>
                    <span className="flex flex-column gap-2  w-full  mt-3">
                        <label htmlFor="Address" className={`${formikEdit?.values.Address ? ' text-md' : ''}`}>{t('city')}</label>
                        <Dropdown filter
                            options={city?.map(item => ({
                                name: item.CityName,
                                value: item.IDCity,
                            }))}
                            id="IDCity"
                            name="IDCity"
                            optionLabel="name"
                            optionValue="value"
                            value={formikEdit.values.IDCity} // Add this line 
                            onChange={(e) => {
                                formikEdit.setFieldValue("IDCity", e.value)
                                fetchArea(e.value)
                            }}
                            onBlur={formikEdit.handleBlur}
                            placeholder={'City'}
                            className="w-full p-inputtext-sm"
                        />
                    </span>
                    <span className="flex flex-column gap-2  w-full  mt-3">
                        <label htmlFor="Address" className={`${formikEdit?.values.Address ? ' text-md' : ''}`}>{t('area')}</label>
                        <Dropdown filter
                            options={area?.map(item => ({
                                name: item.AreaName,
                                value: item.IDArea,
                            }))}
                            id="IDArea"
                            name="IDArea"
                            optionLabel="name"
                            optionValue="value"
                            value={formikEdit.values.IDArea} // Add this line 
                            onChange={(e) => {
                                formikEdit.setFieldValue("IDArea", e.value)

                            }}
                            onBlur={formikEdit.handleBlur}
                            placeholder={'Area'}

                            className="w-full p-inputtext-sm"
                        />
                    </span>
                    <span className="flex flex-column gap-2  w-full  mt-3">
                        <label htmlFor="ClientAddress" className={`${formikEdit?.values.ClientAddress ? ' text-md' : ''}`}>{t('address')}</label>
                        <InputTextarea id="ClientAddress" name='ClientAddress' onChange={formikEdit.handleChange} onBlur={formikEdit.handleBlur} value={formikEdit?.values.ClientAddress} rows={5} cols={30} autoResize />
                    </span>
                    <div className='btn_save_cansel mt-4 flex gap-3 justify-content-center align-item-center'>
                        <Button label={t('cancel')} className='' type='button' onClick={() => setVisibleEdit(false)} outlined severity='' size='small' />
                        <Button loading={loadingEdit} label={t('save')} type='submit' className='p-button-danger  ' size='small' />
                    </div>


                </form>
            </Dialog>

        </>
    )
}

export default MyAddress
