import LogoSvg from 'constants/logo';
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Sidebar } from 'primereact/sidebar'
import { Button } from 'primereact/button';
import { FetchApi } from 'context/FetchApi';
import { Badge } from 'primereact/badge';

const NavMobile = () => {
    const location = useLocation();
    const { t } = useTranslation()
    const [visibleBottom, setVisibleBottom] = useState(false);
    let { cartNum, brands } = useContext(FetchApi);

    return (
        <div className="navbar_mobile w-full">
            <div className="w-full flex justify-content-between align-items-center">

                <Link
                    to={`/`}
                    className={`${location.pathname === '/' ? 'active_nav_mobile' : ''} item_nav w-full flex flex-column justify-content-between align-items-center`}
                >
                    <LogoSvg.Home1 />
                    <div className="header">
                        <span>{t('nav_home')}</span>
                    </div>
                </Link>
                <Link
                    to={`/category`}
                    className={`${location.pathname === '/category' || location.pathname === '/category/search' ? 'active_nav_mobile' : ''} item_nav w-full flex flex-column justify-content-between align-items-center`}
                >
                    <LogoSvg.Categories />
                    <div className="header">
                        <span>{t('nav_categories')}</span>
                    </div>
                </Link>
                <Link
                    to={`${location.pathname}`}
                    onClick={() => setVisibleBottom(true)}
                    className={`${location.pathname.includes('brands') ? 'active_nav_mobile' : ''} item_nav w-full flex flex-column justify-content-between align-items-center`}
                >
                    <LogoSvg.Brands />
                    <div className="header">
                        <span>{t('nav_brands')}</span>
                    </div>
                </Link>
                <Link
                    to={`/about`}
                    className={`${location.pathname === '/about' ? 'active_nav_mobile' : ''} item_nav w-full flex flex-column justify-content-between align-items-center`}
                >
                    <LogoSvg.About />
                    <div className="header">
                        <span>{t('nav_about')}</span>
                    </div>
                </Link>
                <Link
                    to={`/cart`}
                    className={`${location.pathname === '/cart' ? 'active_nav_mobile' : ''} item_nav w-full flex flex-column justify-content-between align-items-center`}
                >
                    <i className="card_icons p-overlay-badge" style={{ fontSize: '2rem', position: 'relative', zIndex: '2' }}>
                        <Badge severity="danger" value={cartNum ? cartNum : 0}></Badge>
                        <LogoSvg.card2 />
                    </i>
                    <div className="header">

                        <span>{t('cart')}</span>
                    </div>
                </Link>

            </div>

            <Sidebar className='add_cart_sm' visible={visibleBottom} position="bottom" onHide={() => setVisibleBottom(false)}>
                <div className="grid">
                    <div className="brand-grid grid pt-5 ">
                        {brands?.map((item, index) => (
                            <div className="col-6">
                                <div key={index} className="brand-icon">
                                    <Link to={`/brands/${item?.IDBrand}`}>
                                        <img src={item.BrandLogo} alt={`Brand ${index}`} />
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </div>


                </div>
            </Sidebar>
        </div>
    )
}

export default NavMobile
