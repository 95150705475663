import CartFusion from 'components/Cart/CartFusion';
import { FetchApi } from 'context/FetchApi';
import { LocalizationContext } from 'context/LangChange';
import { Button } from 'primereact/button';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Carousel from 'react-multi-carousel';
import Slider from "react-slick";

const CategoryHome = ({ data }) => {
  let { isLang } = useContext(LocalizationContext);
  let { t } = useTranslation()
  const [active, setActive] = useState(null)
  let { Category, fetchProducts, products, fetchCategory } = useContext(FetchApi);
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: 4,
      slidesToSlide: 1
    },
    tablet: {
      breakpoint: { max: 1200, min: 767 },
      items: 3,
      slidesToSlide: 1
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1,
      slidesToSlide: 1
    },
    customTablet: {
      breakpoint: { max: 768, min: 480 },
      items: 2,
      slidesToSlide: 1
    },
    customBreakpoint: {
      breakpoint: { max: 1500, min: 1201 },
      items: 4,
      slidesToSlide: 1
    },
    extraSmallMobile: {
      breakpoint: { max: 480, min: 0 },
      items: 1,
      slidesToSlide: 1
    }
  };
  const handleTabChange = useCallback(async (item) => {
    await fetchProducts(30, 1, item?.IDCategory, '', true, false, false, '');
    await setActive(item?.IDCategory);
  }, [fetchProducts]);

  useEffect(() => {
    fetchCategory()
  }, [localStorage.getItem('i18nextLng')])

  return (
    <div className="app_CategoryHome mt-8 mb-8">
      <span className='header'  data-aos="flip-left"
                    data-aos-duration="2000">{t('CATEGORY')}</span>
      <p className='body'  data-aos="flip-left"
                    data-aos-duration="2000">{t('CATEGORY_des')}</p>
      <div className="pb-2 mt-3 horizontal-scroll-container tabs_Category  ">
        <div  className="horizontal-scroll-content">
          {
            data?.map((item, index) => (
              <Button data-aos="fade-up"
              data-aos-duration={500 * (index + 1)} key={index} size='small' raised className={`${item?.IDCategory === active ? 'active' : ''}`} label={item?.CategoryName} onClick={() => handleTabChange(item)} />
            ))
          }
        </div>
      </div>
      <div className={`slider-container   ${isLang === "en" ? 'side_ltr' : 'side_rtl'}`} >
 
        <Carousel
          showDots={true}
          responsive={responsive}
          ssr={true}
          autoPlay
          autoPlaySpeed={2000}
          infinite
          keyBoardControl={true}
          containerClass="carousel-container"
          dotListClass="custom-dot-list-style"
          swipeable={true} // Enable swipe gesture for smooth sliding
          transitionDuration={500} //
        >
        
          {
            products?.map((item, index) => (
              <div data-aos="fade-up"
              data-aos-duration={500 * (index + 1)} key={index} className='app__cars_slider_card h-100 w-100 flex justify-content-center' dir={isLang === "en" ? 'ltr' : 'rtl'}>
                <CartFusion
                  id={item?.IDBrandProduct}
                  path={`/details/${item?.IDBrandProduct}`}
                  title={item?.BrandProductTitle}
                  m={'m-2'}
                  img={item.BrandProductLogo}
                  price={item?.BrandProductPrice}
                  offer={item?.BrandProductOfferPrice}
                />
              </div>
            ))
          }
        </Carousel>
      </div>
    </div>
  )
}

export default CategoryHome
