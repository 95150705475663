import img from 'constants/img'
import { useFormik } from 'formik'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import '../auth.scss'
import axios from 'axios'
import toast from 'react-hot-toast'
import { LocalizationContext } from 'context/LangChange'
import i18next from 'i18next';
import { getCart } from 'helper/cartApis'
import { FetchApi } from 'context/FetchApi'

const Login = () => {
  // const url = `${process.env.REACT_APP_API_URL}/login?ClientAppLanguage=${isLang}`;
  let { isLang, setIsLang, LogOut } = useContext(LocalizationContext);
  let navigate = useNavigate()
  const [handelOpenPassword, setHandelOpenPassword] = useState('password');
  let { setCartsNum } = useContext(FetchApi);

  let { t } = useTranslation()
  const [loading, setLoading] = useState(false);


  const formik = useFormik({
    initialValues: {
      UserName: '',
      Password: '',
      LoginBy: 'MANUAL',
    },
    onSubmit: async (values, { resetForm }) => {

      try {
        setLoading(true);
        let { data } = await axios.post(`${process.env.REACT_APP_API_URL}/login?ClientAppLanguage=${isLang}`, values,
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
          })

        if (data?.Success) {
          localStorage.setItem("token", data.Response.AccessToken);
          localStorage.setItem("IDClient", data.Response.IDClient);
          /* localStorage.setItem('IDCart', data?.Response?.IDCart) */
          i18next.changeLanguage(data.Response.ClientAppLanguage);
          setIsLang(data.Response.ClientAppLanguage); 
          if (data.Response.ClientAppLanguage === 'en') { 
            document.body.dir = 'ltr'; 
          } else { 
            document.body.dir = 'rtl'; 
          }  
          toast.success(isLang === "en" ? 'logged in successfully' : 'تم تسجيل الدخول بنجاح')
          setTimeout(() => {
            fetchCart()
            setLoading(false);
            navigate('/')
            resetForm();
          }, 1000);
        } else {
          setTimeout(() => {
            setLoading(false);
          }, 1000);
          toast.error(data?.ApiMsg)
        }

      } catch ({ response }) {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } 
      fetchCart() 
    }
    
  });
  const fetchCart = () => {
    getCart().then((data) => { 
      console.log(data);
      localStorage.setItem('IDCart', data?.Response?.IDCart)
      setCartsNum(data?.Response?.CartItems?.length > 0 ? data?.Response?.CartItems?.length : 0)
    })
  }
  return (
    <>
      <div className="app_login" dir='ltr'>
        <div className="login_form">
          <div className="logo">
            <img src={img.loginBg} alt="" />
          </div>
          <form onSubmit={formik.handleSubmit} className="field col-12 md:col-12">
            <span className="p-float-label p-input-icon-left w-full mt-5">
              <i className="pi pi-envelope" />
              <InputText id="UserName" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik?.values.UserName} className='w-full  p-inputtext-md' />
              <label htmlFor="UserName" className={`${formik?.values.UserName ? 'text-white text-md' : ''}`}> {t('Email_address')}  </label>
            </span>

            <div className="Password_open p-float-label p-input-icon-left w-full mt-5">
              <i className="pi pi-lock" />
              <InputText
                id="Password"
                name="Password"
                type={handelOpenPassword}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                className='w-full'
                dir='ltr'
                value={formik.values.Password}

              />
              {
                handelOpenPassword === "password" ?
                  <i className="pi pi-eye-slash app__login-btn-icons" onClick={() => setHandelOpenPassword('text')}  ></i> :
                  <i className="pi pi-eye app__login-btn-icons" onClick={() => setHandelOpenPassword('password')}  ></i>
              }
              <label htmlFor="lefticon" className={`${formik?.values.Password ? 'text-white text-md' : ''}`}>{t('password')}</label>
            </div>
            <div className='add_cart mt-4 flex justify-content-center align-item-center'>
              <Button loading={loading} label={t('LOGIN')} className='p-button-danger  w-5' />
            </div>

            <div className='flex justify-content-center  mt-3'>
              <span className='text-white'>{t('Don_account')} <Link to="/register" className='SignUp'>{t('SignUp')}</Link> </span>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default Login