import Component from 'constants/Component';
import FetchApiContext from 'context/FetchApi';
import LangChange from 'context/LangChange';
import { Toaster } from 'react-hot-toast';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import './style/App.scss';

function App() { 
  
  function ProtectedRoutes({ children }) {
    let token = localStorage.getItem('token')
    if (!token) {
      return <Navigate to="/login" replace={true} />
    } else {
      return children;
    }
  }

  function ProtectedSite({ children }) {

    let type = sessionStorage.getItem('CATEGORY_TYPE')
    if (!type) {
      return <Navigate to="/type" replace={true} />
    } else {
      return children;
    }
  }

  const root = createBrowserRouter([
    {
      path: '', element: <Component.Fusion />, children: [
        { index: true, element: <ProtectedSite><Component.Home /></ProtectedSite> },
        {
          path: '/category', children: [
            { index: true, element: <ProtectedSite><Component.Category /></ProtectedSite> },
            { path: 'search', element: <ProtectedSite><Component.CategoryProducts /></ProtectedSite> },
          ]
        },
        {
          path: 'payment', element:
            <ProtectedRoutes>
              <ProtectedSite><Component.Payment /></ProtectedSite>
            </ProtectedRoutes>
        },
        {
          path: 'profile', element:
            <ProtectedRoutes>
              <ProtectedSite><Component.MyProfile /></ProtectedSite>
            </ProtectedRoutes>
        },
        {
          path: 'address', element:
            <ProtectedRoutes>
              <ProtectedSite><Component.MyAddress /></ProtectedSite>
            </ProtectedRoutes>
        },
        { path: 'brands/:id', element: <ProtectedSite><Component.Brands /></ProtectedSite> },
        {
          path: 'orders', element:
            <ProtectedRoutes>
              <ProtectedSite><Component.MyOrder /></ProtectedSite>
            </ProtectedRoutes>
        },
        { path: 'products', element: <ProtectedSite><Component.Products /></ProtectedSite> },
        { path: 'cart', element: <ProtectedSite><Component.Orders /></ProtectedSite> },
        { path: 'details/:id', element: <ProtectedSite><Component.Details /></ProtectedSite> },


        { path: 'about', element: <ProtectedSite><Component.About /></ProtectedSite> },
        {
          path: '*', element: <Component.Error />
        }
      ],
    },
    {
      path: '/', element: <Component.Auth />, children: [
        { path: 'login', element: <Component.Login /> },
        { path: 'register', element: <Component.Register /> },
      ]
    },
    {
      path: '/type', element: <Component.PageType />, children: [
        { index: true, element: <Component.CheckType /> },
      ]
    },
  ])
  const tooltipOptions = {
    position: 'top',
    // يمكنك تحديد لون الخلفية هنا
    style: { backgroundColor: 'blue' },
  };
  return (
    <div className="App">
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          duration: 3500,
          style: {
            fontFamily: ' Arial, Helvetica, sans-serif',
            textTransform: 'capitalize',
            zIndex: '9999',
            background: '#000',
            color: '#fff',
            borderRadius: '10px',
            boxShadow: '10px 10px 10px rgba(188, 188, 188, 0.16)',
            /*      background: '#fff',
                 color: '#000', */
          },
        }}
        containerStyle={{
          bottom: 50
        }}
      />

      <FetchApiContext>
        <LangChange>
          <RouterProvider router={root} />
        </LangChange>
      </FetchApiContext>
    </div>
  );
}

export default App;
